import { gql } from '@apollo/client';
import { requestGql } from 'modules/common/utils/request-gql';
import { CustomEmailInterface } from 'modules/user-company-management/interfaces';

export interface UseFetchCompanyByIdHookInterface {
  fetchCompanyCustomEmails: (companyId: string) => Promise<{ data: CustomEmailInterface[]; totalCount: number }>;
}

export const useFetchCompanyCustomEmails = (): UseFetchCompanyByIdHookInterface => {
  const fetchCompanyCustomEmails = (companyId: string): Promise<{ data: CustomEmailInterface[]; totalCount: number }> =>
    requestGql(
      {
        query: gql`
          query findCustomEmailsForBusinessPartnerInternal($businessPartnerInternalId: ID!) {
            customEmailsForBusinessPartnerInternal(businessPartnerInternalId: $businessPartnerInternalId) {
              data {
                id
                email
                businessPartnerInternalId
              }
              totalCount
            }
          }
        `,
        variables: {
          businessPartnerInternalId: companyId,
        },
      },
      null,
      'customEmailsForBusinessPartnerInternal',
    );

  return {
    fetchCompanyCustomEmails,
  };
};
