import { EntityConfigInterface } from 'modules/acl/interfaces';
import { UserRoleEnum } from 'modules/user-invites/enums';
import routes from 'routes';

export const entityConfig: EntityConfigInterface = {
  home: {
    read: {
      roles: [UserRoleEnum.HELM_INTERNAL_USER, UserRoleEnum.HELM_ADMIN_USER, UserRoleEnum.CUSTOMER_USER],
      route: routes.home,
    },
  },
  overview: {
    read: {
      roles: [UserRoleEnum.HELM_INTERNAL_USER, UserRoleEnum.HELM_ADMIN_USER, UserRoleEnum.CUSTOMER_USER],
      route: routes.overview,
    },
  },
  inquiries: {
    read: {
      roles: [UserRoleEnum.HELM_INTERNAL_USER, UserRoleEnum.HELM_ADMIN_USER, UserRoleEnum.CUSTOMER_USER],
      route: routes.inquiries,
    },
  },
  ordersNavigation: {
    read: {
      roles: [UserRoleEnum.HELM_INTERNAL_USER, UserRoleEnum.HELM_ADMIN_USER, UserRoleEnum.CUSTOMER_USER],
      route: routes.orders,
    },
  },
  contractNavigation: {
    read: {
      roles: [UserRoleEnum.HELM_INTERNAL_USER, UserRoleEnum.HELM_ADMIN_USER, UserRoleEnum.CUSTOMER_USER],
      route: routes.contracts,
    },
  },
  marketIntelligenceNavigation: {
    read: {
      roles: [UserRoleEnum.HELM_INTERNAL_USER, UserRoleEnum.HELM_ADMIN_USER],
      route: routes.marketIntelligence,
    },
  },
  markertIntelligence: {
    read: {
      roles: [UserRoleEnum.HELM_INTERNAL_USER, UserRoleEnum.HELM_ADMIN_USER, UserRoleEnum.CUSTOMER_USER],
      route: routes.marketIntelligence,
    },
    create: {
      roles: [UserRoleEnum.HELM_INTERNAL_USER, UserRoleEnum.HELM_ADMIN_USER],
      route: routes.marketIntelligenceCreate,
    },
    update: {
      roles: [UserRoleEnum.HELM_INTERNAL_USER, UserRoleEnum.HELM_ADMIN_USER],
      route: routes.marketIntelligenceEdit,
    },
  },
  customerMarkertIntelligence: {
    read: {
      roles: [UserRoleEnum.HELM_INTERNAL_USER, UserRoleEnum.HELM_ADMIN_USER, UserRoleEnum.CUSTOMER_USER],
      route: '',
    },
  },
  contractDetails: {
    read: {
      roles: [UserRoleEnum.HELM_INTERNAL_USER, UserRoleEnum.HELM_ADMIN_USER, UserRoleEnum.CUSTOMER_USER],
      route: routes.contractDetails,
    },
  },
  detailsFurtherInformation: {
    read: {
      roles: [UserRoleEnum.HELM_INTERNAL_USER, UserRoleEnum.HELM_ADMIN_USER],
      route: '',
    },
  },
  contracts: {
    read: {
      roles: [UserRoleEnum.HELM_INTERNAL_USER, UserRoleEnum.HELM_ADMIN_USER, UserRoleEnum.CUSTOMER_USER],
      route: routes.contracts,
    },
  },
  customerDashboardContractsCard: {
    read: {
      roles: [UserRoleEnum.HELM_INTERNAL_USER, UserRoleEnum.CUSTOMER_USER],
      route: '',
    },
  },
  adminDashboardContractsCard: {
    read: {
      roles: [UserRoleEnum.HELM_INTERNAL_USER, UserRoleEnum.HELM_ADMIN_USER],
      route: '',
    },
  },
  orders: {
    read: {
      roles: [UserRoleEnum.HELM_INTERNAL_USER, UserRoleEnum.HELM_ADMIN_USER, UserRoleEnum.CUSTOMER_USER],
      route: routes.orders,
    },
  },
  customerOrders: {
    read: {
      roles: [UserRoleEnum.HELM_INTERNAL_USER, UserRoleEnum.CUSTOMER_USER, UserRoleEnum.HELM_ADMIN_USER],
      route: '',
    },
  },
  deliveries: {
    read: {
      roles: [UserRoleEnum.HELM_INTERNAL_USER, UserRoleEnum.HELM_ADMIN_USER, UserRoleEnum.CUSTOMER_USER],
      route: routes.deliveries,
    },
  },
  documents: {
    read: {
      roles: [UserRoleEnum.HELM_INTERNAL_USER, UserRoleEnum.HELM_ADMIN_USER, UserRoleEnum.CUSTOMER_USER],
      route: routes.documents,
    },
  },
  carbonFootprint: {
    read: {
      roles: [UserRoleEnum.HELM_INTERNAL_USER, UserRoleEnum.HELM_ADMIN_USER, UserRoleEnum.CUSTOMER_USER],
      route: routes.carbonFootprint,
    },
  },
  compliance: {
    read: {
      roles: [UserRoleEnum.HELM_INTERNAL_USER, UserRoleEnum.HELM_ADMIN_USER, UserRoleEnum.CUSTOMER_USER],
      route: routes.compliance,
    },
  },
  knowledgeCenter: {
    read: {
      roles: [UserRoleEnum.HELM_INTERNAL_USER, UserRoleEnum.HELM_ADMIN_USER, UserRoleEnum.CUSTOMER_USER],
      route: routes.knowledgeCenter,
    },
  },
  helpCenter: {
    read: {
      roles: [UserRoleEnum.HELM_INTERNAL_USER, UserRoleEnum.HELM_ADMIN_USER, UserRoleEnum.CUSTOMER_USER],
      route: routes.helpCenter,
    },
  },
  deliveryDetails: {
    read: {
      roles: [UserRoleEnum.HELM_INTERNAL_USER, UserRoleEnum.HELM_ADMIN_USER, UserRoleEnum.CUSTOMER_USER],
      route: routes.orderDeliveryDetails,
    },
  },
  orderDetails: {
    read: {
      roles: [UserRoleEnum.HELM_INTERNAL_USER, UserRoleEnum.HELM_ADMIN_USER, UserRoleEnum.CUSTOMER_USER],
      route: routes.orderDetails,
    },
  },
  management: {
    read: {
      roles: [UserRoleEnum.HELM_INTERNAL_USER, UserRoleEnum.HELM_ADMIN_USER],
      route: routes.management,
    },
  },
  administrationUsers: {
    read: {
      roles: [UserRoleEnum.HELM_INTERNAL_USER, UserRoleEnum.HELM_ADMIN_USER],
      route: routes.adminstrationUsers,
    },
  },
  companies: {
    read: {
      roles: [UserRoleEnum.HELM_INTERNAL_USER, UserRoleEnum.HELM_ADMIN_USER],
      route: routes.companies,
    },
  },
  companyCustomEmail: {
    read: {
      roles: [UserRoleEnum.HELM_INTERNAL_USER, UserRoleEnum.HELM_ADMIN_USER],
      route: routes.companyCustomEmails,
    },
  },
  customers: {
    read: {
      roles: [UserRoleEnum.HELM_INTERNAL_USER, UserRoleEnum.HELM_ADMIN_USER],
      route: routes.customersUsers,
    },
  },
  invitations: {
    read: {
      roles: [UserRoleEnum.HELM_INTERNAL_USER, UserRoleEnum.HELM_ADMIN_USER],
      route: routes.invitations,
    },
  },
  helmUsers: {
    read: {
      roles: [UserRoleEnum.HELM_INTERNAL_USER, UserRoleEnum.HELM_ADMIN_USER],
      route: routes.helmUsers,
    },
  },
  userInvitesEdit: {
    read: {
      roles: [UserRoleEnum.HELM_INTERNAL_USER, UserRoleEnum.HELM_ADMIN_USER],
      route: routes.userInvitesEdit,
    },
  },
  customerInviteSend: {
    read: {
      roles: [UserRoleEnum.CUSTOMER_USER],
      route: '',
    },
    create: {
      roles: [UserRoleEnum.CUSTOMER_USER],
      route: '',
    }
  },
  dashboard: {
    read: {
      roles: [UserRoleEnum.HELM_INTERNAL_USER, UserRoleEnum.HELM_ADMIN_USER, UserRoleEnum.CUSTOMER_USER],
      route: routes.dashboard,
    },
  },
  twoFactorRegistration: {
    read: {
      roles: [UserRoleEnum.HELM_INTERNAL_USER, UserRoleEnum.HELM_ADMIN_USER, UserRoleEnum.CUSTOMER_USER],
      route: routes.twoFactorAuthRegistration,
    },
  },
  twoFactorAuthentication: {
    read: {
      roles: [UserRoleEnum.HELM_INTERNAL_USER, UserRoleEnum.HELM_ADMIN_USER],
      route: routes.twoFactorAuthAuthenticate,
    },
  },
  orderPrice: {
    read: {
      roles: [UserRoleEnum.HELM_INTERNAL_USER, UserRoleEnum.HELM_ADMIN_USER, UserRoleEnum.CUSTOMER_USER],
      route: '',
    },
  },
  notifications: {
    read: {
      roles: [UserRoleEnum.HELM_INTERNAL_USER, UserRoleEnum.CUSTOMER_USER, UserRoleEnum.HELM_ADMIN_USER],
      route: '',
    },
  },
  notificationsPriceHistoryPreference: {
    read: {
      roles: [UserRoleEnum.HELM_INTERNAL_USER, UserRoleEnum.CUSTOMER_USER, UserRoleEnum.HELM_ADMIN_USER],
      route: '',
    },
  },
  marketIntelligenceProductDetails: {
    read: {
      roles: [UserRoleEnum.HELM_INTERNAL_USER, UserRoleEnum.HELM_ADMIN_USER, UserRoleEnum.CUSTOMER_USER],
      route: routes.marketIntelligenceProductDetails,
    },
  },
};
