import { useTranslation } from 'modules/common/hooks';
import { yup } from 'modules/common/validation';
import { UserInviteInterface } from 'modules/user-invites';
import { UserRoleEnum } from 'modules/user-invites/enums';

const businessEntitiesValidation = yup
  .mixed()
  .test('is-array-or-object', 'Business Entities must not be empty', value => Array.isArray(value) || typeof value === 'object')
  .when('userRole', {
    is: (userRole: string) => userRole === UserRoleEnum.HELM_ADMIN_USER || userRole === UserRoleEnum.HELM_INTERNAL_USER,
    then: schema =>
      schema.test('valid-array-or-object', 'Business Entities are required', value => {
        if (Array.isArray(value)) {
          return value.length > 0;
        }
        return !!value;
      }),
    otherwise: schema => schema.nullable(),
  });

export const useInvitesFormSchema = (): yup.AnyObjectSchema => {
  const { t } = useTranslation();
  return yup.object({
    userRole: yup.string().required('User Role is required'),
    invitedCompanies: yup
      .array()
      .nullable()
      .when('userRole', {
        is: (userRole: string) => userRole === UserRoleEnum.CUSTOMER_USER,
        then: schema =>
          schema.min(1, t('input.invitedCompanies.validation.required')).required(t('input.invitedCompanies.validation.required')),
        otherwise: schema => schema,
      }),
    businessEntities: businessEntitiesValidation,
    invites: yup
      .array<UserInviteInterface>()
      .required()
      .of(
        yup.object().shape({
          name: yup.string().trim().required('Name is a required field'),
          email: yup
            .string()
            .required(t('input.email.validation.required'))
            .uniqueEmail(t('input.email.validation.unique'))
            .validEmail(t('input.email.validation.type')),
        }),
      ),
  });
};