import { AuthUserInterface } from 'modules/auth';
import { UserRoleEnum } from 'modules/user-invites/enums';
import { useSession } from 'next-auth/react';
import { useMemo } from 'react';

export interface UserRolesResponseInterface {
  isLoading: boolean;
  names: string[];
  roles: UserRoleEnum[];
  isCustomer: boolean;
  isInternalAdmin: boolean;
}

export const useUserRoles = (): UserRolesResponseInterface => {
  const { data, status } = useSession();
  const isLoading = useMemo(() => status === 'loading', [status]);
  const userUserRoles = useMemo(() => (data?.user as AuthUserInterface)?.userUserRoles?.data, [data]);
  const names = useMemo(() => userUserRoles?.map((role) => role.userRole?.name), [userUserRoles]);
  const roles = useMemo(() => userUserRoles?.map((role) => role?.userRole?.key as UserRoleEnum), [userUserRoles]);
  const isCustomer = roles?.includes(UserRoleEnum.CUSTOMER_USER);
  const isInternalAdmin = roles?.includes(UserRoleEnum.HELM_INTERNAL_USER);

  return {
    isLoading,
    names,
    roles,
    isCustomer,
    isInternalAdmin
  };
};
