import { NavigationItemInterface } from 'layouts/main/interfaces';
import { entityConfig } from 'modules/acl/config/entity.config';
import { RouteUrlInterface } from 'modules/common/interfaces';
import { UserRoleEnum } from 'modules/user-invites/enums';

export const navigationItemAuthFilter = (
  userRoles: UserRoleEnum[],
  menuItems: NavigationItemInterface[][],
): NavigationItemInterface[][] => {
  const permissibleRoutes = Object.keys(entityConfig).reduce((acc, curr) => {
    const hasRole = entityConfig[curr].read.roles.filter((r) => userRoles?.includes(r));
    if (hasRole.length) {
      return acc.concat(entityConfig[curr]?.read.route);
    }
    return acc;
  }, []);
  return menuItems.map((group) =>
    group?.filter((item) => permissibleRoutes.includes((item.href as RouteUrlInterface).route)),
  );
};
