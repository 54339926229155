import { gql } from '@apollo/client';
import { requestGql } from 'modules/common/utils/request-gql';

export interface UseActivateUserInterface {
  deleteUser: (id: string) => Promise<string>;
}

export const useDeleteUser = (): UseActivateUserInterface => {
  const deleteUser = async (id: string): Promise<string> =>
    requestGql<string>({
      mutation: gql`
        mutation DeleteUser($id: ID!) {
          deleteUser(id: $id)
        }
      `,
      variables: { id },
    });

  return {
    deleteUser,
  };
};
