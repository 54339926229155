/* eslint-disable @typescript-eslint/no-explicit-any */
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useHeaderMenuStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 'auto',
    padding: 0,
    backgroundColor: 'inherit',
    [theme.breakpoints.up('md')]: {
      alignItems: 'center'
    }
  },
  badge: {
    padding: '8px !important',
    '& .MuiBadge-badge': {
      right: -11,
    },
    '& .MuiBadge-colorError': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.error.main,
    },
  },
  accountDropdownMenu: {
    background: (theme.palette as any).avatarFill,
    color: '#FFF',
    fontWeight: 600,
  },
  accountDropdownMenuMaskLayer: {},
}));
