import {
  Certificate,
  CirclesFour,
  FileText,
  Footprints,
  House,
  Lightbulb,
  Paperclip,
  Question,
  Receipt,
  ShoppingCartSimple,
  Truck,
  UserCircleGear,
} from '@phosphor-icons/react';
import { NavigationItemInterface } from 'layouts/main/interfaces';
import { navigationItemAuthFilter } from 'modules/acl/utils';
import { useTranslation } from 'modules/common/hooks';
import { useUserRoles } from 'modules/users/hooks';
import React from 'react';
import routes from 'routes';

interface UseNavigationMenuHookInterface {
  navigationMenuItems: NavigationItemInterface[][];
}

export const useNavigationMenu = (): UseNavigationMenuHookInterface => {
  const { t } = useTranslation();
  const { roles } = useUserRoles();
  const iconSize = 20;

  const navigationMenuItems: NavigationItemInterface[][] = [
    [
      {
        id: 'home',
        title: t('title_home'),
        href: { route: routes.home },
        icon: <House size={iconSize} />,
        isReleased: true,
      },
      {
        id: 'overview',
        title: t('title_overview'),
        href: { route: routes.overview },
        icon: <CirclesFour size={iconSize} />,
        isReleased: true,
      },
      {
        id: 'inquiries',
        title: t('title_inquiries_offers'),
        href: { route: routes.inquiries },
        icon: <Receipt size={iconSize} />,
        isReleased: false,
      },
      {
        id: 'contracts',
        title: t('title_contracts'),
        href: { route: routes.contracts },
        icon: <FileText size={iconSize} />,
        isReleased: true,
      },
      {
        id: 'order',
        title: t('title_orders'),
        href: { route: routes.orders },
        icon: <ShoppingCartSimple size={iconSize} />,
        isReleased: true,
      },
      {
        id: 'deliveries',
        title: t('title_deliveries'),
        href: { route: routes.deliveries },
        icon: <Truck size={iconSize} />,
        isReleased: true,
      },
      {
        id: 'documents',
        title: t('title_documents'),
        href: { route: routes.documents },
        icon: <Paperclip size={iconSize} />,
        isReleased: true,
      },
      {
        id: 'carbon-footprint',
        title: t('title_carbon_footprint'),
        href: { route: routes.carbonFootprint },
        icon: <Footprints size={iconSize} />,
        isReleased: false,
      },
      {
        id: 'compliance',
        title: t('title_compliance'),
        href: { route: routes.compliance },
        icon: <Certificate size={iconSize} />,
        isReleased: false,
      },
      {
        id: 'knowledge-center',
        title: t('title_knowledge_center'),
        href: { route: routes.knowledgeCenter },
        icon: <Lightbulb size={iconSize} />,
        isReleased: false,
      },
    ],
    [
      {
        id: 'help-center',
        title: t('title_help_center'),
        href: { route: routes.helpCenter },
        icon: <Question size={iconSize} />,
        isReleased: false,
      },
      {
        id: 'administration',
        title: t('administration'),
        href: { route: routes.adminstrationUsers },
        icon: <UserCircleGear size={iconSize} />,
        activeOnPaths: [routes.adminstrationUsers, routes.adminstrationCustomers],
        isReleased: true,
      },
    ],
  ];

  return {
    navigationMenuItems: navigationItemAuthFilter(roles, navigationMenuItems),
  };
};
