import { FormControl, FormHelperText, Grid, Input, MenuItem, Select, TextField, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import { FormikErrors } from 'formik';
import isArray from 'lodash/isArray';
import { useAuth } from 'modules/auth/hooks';
import { BusinessUnitAutocomplete } from 'modules/common/components/business-unit-autocomplete/business-unit-autocomplete.component';
import { CustomLabel } from 'modules/common/components/custom-label/custom-label.component';
import { MessageBar } from 'modules/common/components/message-bar/message-bar.component';
import { AutocompleteCompany } from 'modules/forms/components/autocomplete-company';
import { AutocompleteCompanyOptionItemInterface } from 'modules/forms/components/autocomplete-company/autocomplete-company.component';
import { BusinessEntitiesAutocompleteComponent } from 'modules/user-company-management/components';
import { BusinessEntityInterface } from 'modules/user-company-management/interfaces';
import { UserInviteInterface } from 'modules/user-invites';
import { UserRoleEnum } from 'modules/user-invites/enums';
import { UserRole } from 'modules/user-invites/interfaces/user-invite.interface';
import { useIsAdminUser, useIsHelmUser, useUserRoles } from 'modules/users/hooks';
import { ChangeEvent, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useFetchUserRoles } from 'views/user-invite/hooks';
import { InviteFormRow } from 'views/user-invite/partials/invite-form-row/invite-form-row.partial';
import { UserInviteFormValueInterface } from 'views/user-invite/partials/invite-list/invite-list.partial';

export interface InviteFormInterface {
  invites: UserInviteInterface[];
  errors: FormikErrors<UserInviteFormValueInterface>;
  onBlur: (e: React.FocusEvent) => void;
  onChange: (field: string, value: unknown, shouldValidate?: boolean) => void;
  onSubmit: () => void;
  onReset: (e: unknown) => void;
  disableSendInvite: boolean;
  handleChange: (e: ChangeEvent) => void;
  isSubmitting: boolean;
}

export const InviteFormPartial: FunctionComponent<InviteFormInterface> = (props: InviteFormInterface) => {
  const { invites, errors, onBlur, onChange, handleChange, onReset } = props;
  const [companies, setCompanies] = useState<AutocompleteCompanyOptionItemInterface[]>([]);
  const { fetchUserRoles } = useFetchUserRoles();
  const { user, isCustomerWithSingleBusinessPartnerInternal } = useAuth();
  const isHelm = useIsHelmUser();
  const isAdmin = useIsAdminUser();
  const [roles, setRoles] = useState<UserRole[]>([]);
  const [selectedEntities, setSelectedEntities] = useState<BusinessEntityInterface | BusinessEntityInterface[]>();
  const [selectedRole, setSelectedRole] = useState<UserRoleEnum>();
  const [key, setKey] = useState<number>(0);

  const userRoles = user?.userUserRoles.data;

  const isEntityDropdownMultiSelect = selectedRole === UserRoleEnum.HELM_ADMIN_USER;
  const showCompanyAutoComplete = selectedRole === UserRoleEnum.CUSTOMER_USER;

  useEffect(() => {
    void (async () => {
      if (!isAdmin) {
        handleSetSelectedRole(UserRoleEnum.CUSTOMER_USER);
      }
      const filter = isAdmin
        ? {}
        : {
          isHelm: {
            equalTo: false,
          },
        };
      const { data } = await fetchUserRoles({
        offset: 0,
        limit: 20,
        filter,
      });
      setRoles(data);
    })();
  }, [userRoles]);

  const handleEntitiesChange = (values) => {
    setSelectedEntities(values);
    const businessEntities = values ? (isArray(values) ? values : [values]) : null;
    props.onChange('businessEntities', businessEntities);
  };

  const handleSetSelectedRole = (roleKey: UserRoleEnum) => {
    setSelectedRole(roleKey);
    props.onChange('userRole', roleKey);
    const val = roleKey === UserRoleEnum.HELM_ADMIN_USER ? [] : null;
    setSelectedEntities(val);
    setKey(Math.random());
  };

  const handleRoleChange = ({ target }) => {
    handleSetSelectedRole(target.value);
  };

  const [illegalCompanyCombo, setIllegalCompanyCombo] = useState(false);

  const onDelete = useCallback(
    (index) =>
      onChange(
        'invites',
        invites.filter((_, currIndex) => currIndex !== index),
      ),
    [invites],
  );

  const { isCustomer } = useUserRoles();

  const companyIds = companies.map((c) => c.id);
  useEffect(() => {
    let includesHelm = false;
    let includesCustomer = false;
    companyIds.forEach((c) => {
      if (c === 'HELM') {
        includesHelm = true;
      } else {
        includesCustomer = true;
      }
    });

    if (includesHelm && includesCustomer) {
      setIllegalCompanyCombo(true);
    } else {
      setIllegalCompanyCombo(false);
    }
  }, [companyIds]);


  return (
    <Grid container>
      <Grid item xs={12} padding={'24px'} rowGap={'14px'}>
        {!isCustomer &&
          <Grid item xs={12}>
            <CustomLabel value="Role" />
            <FormControl variant="filled" sx={{ margin: '0 !important' }} fullWidth>
              <Select
                onChange={handleRoleChange}
                onBlur={onBlur}
                onReset={onReset}
                value={isAdmin ? selectedRole : UserRoleEnum.CUSTOMER_USER}
                disabled={!isAdmin}
                error={!!errors?.userRole}
              >
                {roles.map((option) => (
                  <MenuItem key={option.key} value={option.key}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
              {errors?.userRole && (
                <FormHelperText sx={{ color: '#F75959' }}>{'Role is a required field'}</FormHelperText>
              )}
            </FormControl>
          </Grid>
        }

        {!isCustomer && <Grid item xs={12} marginTop={'14px'}>
          <BusinessUnitAutocomplete />
        </Grid>}

        {isCustomer && <Grid item sm={6} md={6} lg={6}>
          <Typography fontWeight={600} fontSize={'16px'} lineHeight={'133.4%'} color={'black'}>Company</Typography>
        </Grid>}

        {!isHelm || showCompanyAutoComplete ?
          (
            <Grid item xs={12} marginTop={'14px'}>
              <AutocompleteCompany
                includeHardcodedCompanyData
                helperText={errors?.invitedCompanies}
                variant="filled"
                name={'invitedCompanies'}
                label={isCustomer ? 'Select Company' : 'Companies'}
                multiple
                disabled={isCustomerWithSingleBusinessPartnerInternal}
                value={companies}
                error={Boolean(errors?.invitedCompanies)}
                onChange={(value: AutocompleteCompanyOptionItemInterface[]) => {
                  setCompanies(value);
                  props.onChange(
                    'invitedCompanies',
                    value.map((x) => x.id),
                  );
                }}
                onBlur={onBlur}
              />
            </Grid>
          ) : (
            <Grid item xs={12} marginTop={'14px'}>
              <BusinessEntitiesAutocompleteComponent
                key={key}
                multiple={isEntityDropdownMultiSelect}
                label={'Companies'}
                value={selectedEntities}
                onChange={handleEntitiesChange}
                helperText={errors?.businessEntities}
                error={Boolean(errors?.businessEntities)}
              />
            </Grid>
          )}
      </Grid>
      {illegalCompanyCombo && (
        <Grid item px={3}>
          <MessageBar severity="error" title="You can invite users either to Helm or to customer companies" />
        </Grid>
      )}

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid
        item
        xs={12}
        sx={{ pointerEvents: illegalCompanyCombo ? 'none' : 'inherit', opacity: illegalCompanyCombo ? 0.5 : 1 }}
      >
        {invites.map((invite, index) => (
          <>
            <InviteFormRow
              handleChange={handleChange}
              companyIds={companyIds}
              length={invites.length}
              onChange={onChange}
              key={`invite-form-${index}`}
              index={index}
              visibleErrors={errors?.invites?.[index] as unknown as FormikErrors<UserInviteInterface>}
              onBlur={onBlur}
              invite={invite}
              onDelete={onDelete}
              onReset={onReset}
              isCurrentUserCustomer={isCustomer}
              parentGridStyling={
                index === invites?.length - 1 ? { paddingBottom: '0px !important', marginBottom: '14px' } : {}
              }
            />
            {index < invites?.length - 1 ? <Divider variant="fullWidth" /> : null}
          </>
        ))}
      </Grid>
    </Grid>
  );
};
