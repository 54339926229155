/* eslint-disable @roq/no-invalid-resource-location */
/* eslint-disable @roq/no-invalid-partial-and-component-resource */
/* eslint-disable @roq/correct-export-name-components-layouts-actions */
import { FormControl, FormControlProps, InputLabel } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import debounce from 'lodash/debounce';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import { useUserCompany } from 'modules/auth/hooks';
import { CommonEnum } from 'modules/common/enums';
import { useFetchCompanies } from 'modules/user-company-management/hooks';
import { useIsHelmUser } from 'modules/users/hooks';
import { useEffect } from 'react';
import * as React from 'react';

export interface AutocompleteCompanyOptionItemInterface {
  id?: string;
  label?: string;
  name?: string;
  kunnr?: string;
}

interface IProps {
  label?: string;
  multiple?: boolean;
  value?: AutocompleteCompanyOptionItemInterface;
  defaultValue?: string;
  name?: string;
  disabled?: boolean;
  variant?: 'outlined' | 'standard' | 'filled';
  showIdNameFilter?: boolean;
  placeholder?: string;
  helperText?: React.ReactNode;
  error?: boolean;
  formControlProps?: Omit<FormControlProps, 'variant'>;
  onChange?: (selectedValue: AutocompleteCompanyOptionItemInterface) => void;
  onBlur?: (e: React.SyntheticEvent) => void;
  includeHardcodedCompanyData?: boolean;
  defaultId?: string;
}

export const AutocompleteCompanySingle: React.FunctionComponent<IProps> = (props: IProps) => {
  const {
    label,
    variant,
    formControlProps,
    error,
    onChange,
    placeholder,
    includeHardcodedCompanyData,
    disabled,
    defaultId,
  } = props;
  const { fetchCompanies } = useFetchCompanies();
  const { data: companyData } = useUserCompany();
  const [value, setValue] = React.useState<AutocompleteCompanyOptionItemInterface | null>(props.value || null);
  const [inputValue, setInputValue] = React.useState('');
  const isAdmin = useIsHelmUser();
  const [options, setOptions] = React.useState<readonly AutocompleteCompanyOptionItemInterface[]>([]);

  const [isLoading, setLoading] = React.useState<boolean>(false);

  const helmRecord = {
    id: CommonEnum.HELM,
    label: CommonEnum.HELM,
    kunnr: '0',
    name: 'Helm',
  };

  useEffect(() => {
    if (defaultId) {
      setInputValue(defaultId);
    }
  }, [defaultId]);

  const fetchResults = React.useMemo(
    () =>
      debounce(async (request: { input: string }, callback: (results?) => void) => {
        const filterByName = !isAdmin
          ? {
            name: {
              iLike: `%${request.input}%`,
            },
            id: {
              valueIn: companyData.map((x) => x.id),
            },
          }
          : {
            name: {
              iLike: `%${request.input}%`,
            },
          };

        const filterById = !isAdmin
          ? {
            kunnr: {
              iLike: `%${request.input}%`,
            },
            id: {
              valueIn: companyData.map((x) => x.id),
            },
          }
          : {
            kunnr: {
              iLike: `%${request.input}%`,
            },
          };

        const companiesByName = await fetchCompanies({
          limit: 100,
          filter: filterByName,
        });

        const incluldeHelm = includeHardcodedCompanyData && !companyData?.length;

        if (companiesByName?.data?.length > 0) {
          const filteredCompanies = incluldeHelm
            ? [helmRecord, ...companiesByName.data.map((d) => ({ id: d.id, label: d.name, kunnr: d.kunnr }))]
            : companiesByName.data.map((d) => ({ id: d.id, label: d.name, kunnr: d.kunnr }));

          callback(filteredCompanies);
        } else {
          const companiesById = await fetchCompanies({
            limit: 100,
            filter: filterById,
          });

          const filteredCompanies = incluldeHelm
            ? [helmRecord, ...companiesById.data.map((d) => ({ id: d.id, label: d.name, kunnr: d.kunnr }))]
            : companiesById.data.map((d) => ({ id: d.id, label: d.name, kunnr: d.kunnr }));

          callback(filteredCompanies);
        }
      }, 400),
    [includeHardcodedCompanyData],
  );

  useEffect(() => {
    setLoading(true);
    void fetchResults({ input: inputValue }, (results?: AutocompleteCompanyOptionItemInterface[]) => {
      const sortedOptions = results?.sort((a, b) => a.label?.localeCompare(b.label));
      const helmIndex = findIndex(sortedOptions, { kunnr: '0' });

      if (value?.id && value?.name && !find(sortedOptions, { id: value.id })) {
        sortedOptions.unshift(value);
      }

      if (helmIndex >= 1) {
        sortedOptions.splice(helmIndex, 1);
        sortedOptions.unshift(helmRecord);
      }

      setOptions(sortedOptions);

      setLoading(false);
    });
  }, [inputValue]);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const formatLabel = (option: AutocompleteCompanyOptionItemInterface) =>
    `${option?.name || option?.label} - ${option?.kunnr}`;

  return (
    <>
      {label ? <InputLabel>{label}</InputLabel> : null}
      <FormControl sx={{ margin: 0 }} fullWidth variant={variant} {...formControlProps} error={error}>
        <Autocomplete
          options={options || []}
          value={value}
          disabled={disabled}
          onChange={(_, newValue: AutocompleteCompanyOptionItemInterface) => {
            onChange(newValue);
          }}
          isOptionEqualToValue={(
            option: AutocompleteCompanyOptionItemInterface,
            valueCheck: AutocompleteCompanyOptionItemInterface,
          ) => option.id === valueCheck?.id || option.name === valueCheck?.toString()}
          getOptionLabel={(option: AutocompleteCompanyOptionItemInterface) => formatLabel(option)}
          onInputChange={(_, newInputValue, reason) => {
            if (reason === 'input') {
              setInputValue(newInputValue || '');
            }
            if (reason === 'clear') {
              if (inputValue) {
                setInputValue('')
              }
            }
          }}
          renderInput={(params) => (
            <>
              <TextField
                sx={{ margin: 0 }}
                {...params}
                size="small"
                variant="filled"
                placeholder={placeholder}
                error={Boolean(error)}
              />
            </>
          )}
          loading={isLoading}
        />
      </FormControl>
    </>
  );
};
