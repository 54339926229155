import { useTranslation } from 'modules/common/hooks';
import { yup } from 'modules/common/validation';
import { UserRoleEnum } from 'modules/user-invites/enums';

const businessEntitiesValidation = yup
  .mixed()
  .test('is-array-or-object', 'Business Entities must not be empty', value => Array.isArray(value) || typeof value === 'object')
  .when('userRoles', {
    is: (userRoles: string) => userRoles?.includes(UserRoleEnum.HELM_ADMIN_USER) || userRoles.includes(UserRoleEnum.HELM_INTERNAL_USER),
    then: schema =>
      schema.test('valid-array-or-object', 'Business Entities are required', value => {
        if (Array.isArray(value)) {
          return value.length > 0;
        }
        return !!value;
      }),
    otherwise: schema => schema.nullable(),
  });


export const useEditUserFormSchema = (): yup.AnyObjectSchema => {
  const { t } = useTranslation();
  return yup.object({
    businessPartnerInternals: yup
      .array()
      .test('is-array', 'Companies must not be empty', value => Array.isArray(value))
      .when('userRoles', {
        is: (userRoles: string) => userRoles?.includes(UserRoleEnum.CUSTOMER_USER),
        then: schema =>
          schema.test('valid-array', 'Companies are required', value => value.length > 0),
        otherwise: schema => schema.nullable(),
      }),
    businessEntities: businessEntitiesValidation,
    userRoles: yup
      .array()
      .min(1, t('input.userRole.validation.required'))
      .nullable(),
    name: yup.string().trim().required(t('input.first-name.validation.required')),
    firstName: yup.string().trim(),
    lastName: yup.string().trim(),
    locale: yup.object().required(t('input.locale.validation.required')),
    timezone: yup.string().trim().optional().nullable(),
  });
};
