import { gql } from '@apollo/client';
import { AppDispatch } from 'configuration/redux/store';
import { fetchUserByRoqIdAction } from 'modules/users/users.slice';
import { useDispatch } from 'react-redux';

export interface UseGetUserHookInterface {
  fetchUserByRoqId: (id: string) => void;
}

export const useFetchUserByRoqId = (): UseGetUserHookInterface => {
  const dispatch = useDispatch<AppDispatch>();

  const fetchUserByRoqId = (id: string) => {
    void dispatch(
      fetchUserByRoqIdAction({
        query: gql`
          query userByRoqIdQuery($id: ID!) {
            userByRoqId(id: $id) {
              id
              email
              # disable the avatar selection until we resolved the issue in backend
              firstName
              lastName
              locale
              active
              phone
              department
              position
              optedInAt
              timezone
              userBusinessPartnerInternals {
                data {
                  businessPartnerInternalId
                  businessPartnerInternal {
                    id
                    kunnr
                    name
                  }
                  businessEntityId
                  businessEntity{
                    id
                    name
                  }
                }
              }
              userUserRoles {
                totalCount
                data {
                  userRole {
                    key
                    name
                  }
                }
              }
            }
          }
        `,
        variables: {
          id,
        },
      }),
    );
  };

  return {
    fetchUserByRoqId,
  };
};
