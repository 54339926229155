import { Autocomplete, Checkbox, TextField } from '@mui/material';
import find from 'lodash/find';
import { AutocompleteTag } from 'modules/common/components/autocomplete-tag/autocomplete-tag.component';
import { CustomLabel } from 'modules/common/components/custom-label/custom-label.component';
import { BusinessOptionInterface } from 'modules/common/interfaces/business-option.interface';
import { BusinessPropsInterface } from 'modules/common/interfaces/business-props.interface';
import { useState } from 'react';
import { ReactElement } from 'react-markdown/lib/react-markdown';


const options: BusinessOptionInterface[] = [{ label: 'Chemicals', value: 'Chemicals' }]

export const BusinessUnitAutocomplete = ({ value = [], onChange }: BusinessPropsInterface): ReactElement => {
  const [selectedValue, setSelectedValue] = useState<BusinessOptionInterface[]>(value);

  return (
    <>
      <CustomLabel value='Business Unit' />
      <Autocomplete
        multiple={true}
        fullWidth
        disabled
        value={selectedValue}
        sx={{
          '& .MuiFormControl-root': {
            margin: 0
          }
        }}
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            variant='filled'
            size='small'
            fullWidth
            placeholder={options[0].label}
            inputProps={{
              ...params.inputProps,
              endAdornment: (
                <>
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
        renderTags={(tagValue: BusinessOptionInterface[], getTagProps) => {
          const filteredTags = tagValue.filter((t) => Boolean(find(options, { value: t.value })));
          return filteredTags.map((option, index) => (
            <AutocompleteTag
              key={index}
              index={index}
              getTagProps={getTagProps}
              label={option.label}
            />
          ));
        }}
        renderOption={(renderOptionProps, option: BusinessOptionInterface, { selected }) => {
          const liProps = {
            ...renderOptionProps,
            ...{ key: option?.value },
          };

          return (
            <li {...liProps}>
              <Checkbox style={{ marginRight: 8 }} checked={selected} />
              {option?.label}
            </li>
          );
        }}

        onChange={(_, optionValue: BusinessOptionInterface[]) => {
          setSelectedValue(optionValue)
          onChange?.(optionValue)
        }}
      />
    </>
  )
}
