import { gql } from '@apollo/client';
import { requestGql } from 'modules/common/utils/request-gql';
import { CompanyInterface } from 'modules/user-company-management/interfaces';

export interface UseFetchCompanyByIdHookInterface {
  fetchCompanyById: (id: string) => Promise<CompanyInterface>;
}

export const useFetchCompanyById = (): UseFetchCompanyByIdHookInterface => {
  const fetchCompanyById = (id: string): Promise<CompanyInterface> =>
    requestGql(
      {
        query: gql`
          query fetchCompanyById($id: ID!) {
            businessPartnerInternal(id: $id) {
              name
              kunnr
            }
          }
        `,
        variables: {
          id,
        },
      },
      null,
      'businessPartnerInternal',
    );

  return {
    fetchCompanyById,
  };
};
