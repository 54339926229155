import { Theme } from '@mui/material/styles';
import { darkThemeConfig } from 'configuration/theme/roqone/dark';
import { lightThemeConfig } from 'configuration/theme/roqone/light';
import { roqMakeStyles } from 'modules/common/utils/roq-make-styles';

export const useInviteFormRowStyles = roqMakeStyles((theme: Theme) => ({
  divider: {
    color: '#e9eaed',
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(7),
  },
  trashIcon: {
    paddingLeft: 0,
    color: theme.palette.mode === 'light' ? lightThemeConfig.palette.error.dark : darkThemeConfig.palette.error.dark,
  },
  colName: {
    color: '#626262',
    fontSize: '14px',
    fontWeight: 400,

  },
  icons: {
    gap: '6px',
    alignItems: 'center',
    justifyContent: 'end'
  },
  pointerCursor: {
    cursor: 'pointer',
  },
}));
