import { MessageBar } from 'modules/common/components/message-bar/message-bar.component';
import { useTopbarMessage } from 'modules/common/components/message-top-bar/hooks/use-topbar-message.hook';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const MessageTopBar = (): React.ReactElement => {
  const { hideTopbarMessage, topbarMessage } = useTopbarMessage();

  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => {
      hideTopbarMessage();
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router, hideTopbarMessage]);

  if (!topbarMessage) return <></>;

  const { autoClose, closeAfterMs, showClose } = topbarMessage;

  let shouldShowClose = true;

  if (autoClose || closeAfterMs) {
    shouldShowClose = false;
  }
  if (showClose) {
    shouldShowClose = true;
  }

  return (
    <MessageBar
      sx={{
        marginY: '24px',
        height: '50px',
        boxShadow: 'none !important',
        '&  *': {
          overflow: 'hidden !important'
        },
        alignItems: 'center',
        '& .MuiPaper-root': {
          alignItems: 'center'
        },
        '& .MuiAlert-message': {
          padding: '0px !important',
          marginTop: '2px',
          '& .MuiTypography-root': {
            margin: '0px !important',
            fontFamily: 'Graphik-SemiBold',
            fontSize: '16px',
            lineHeight: '150%',
            letterSpacing: '0.15px',
          }
        },
        '& .MuiAlert-icon': {
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '7px 12px 7px 0px !important',
          marginRight: '0px !important',
        },
        '& .MuiAlert-action': {
          marginRight: '0px !important',
          padding: '0px !important'
        }
      }}
      severity={topbarMessage.severity}
      title={topbarMessage.title}
      onClose={shouldShowClose ? hideTopbarMessage : null}
    />
  );
};
