import { Divider, Grid, GridProps, Paper, Typography } from '@mui/material';
import clsx from 'clsx';
import { useCommonPaperStyles } from 'modules/common/components/common-paper/common-paper.styles';
import { isValidElement, ReactElement, ReactNode } from 'react';

export interface CommonPaperInterface {
  title?: ReactNode;
  children?: ReactNode;
  bodyProps?: GridProps;
  paperClass?: string
}

export const CommonPaper = (props: CommonPaperInterface): ReactElement => {
  const { bodyProps, title, children, paperClass = '' } = props;
  const classes = useCommonPaperStyles();
  return (
    <Paper className={
      clsx(classes.paper, paperClass)
    } elevation={0}
    >
      {title && (
        <>
          <Grid container className={classes.title}>
            <Grid item xs={12}>
              {!isValidElement(title) && <Typography variant="h4">{title}</Typography>}
              {isValidElement(title) && <>{title}</>}
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <Divider className={classes.divider} />
            </Grid>
          </Grid>
        </>
      )}

      <Grid container item xs="auto" {...bodyProps}>
        {children}
      </Grid>
    </Paper>
  );
};
