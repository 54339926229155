import Drawer from '@mui/material/Drawer';
import SvgIcon from '@mui/material/SvgIcon';
import { CaretDoubleLeft, CaretDoubleRight } from '@phosphor-icons/react';
import clsx from 'clsx';
import { useNavigationStyles } from 'layouts/main/partials/navigation/navigation.styles';
import { NavigationMenu } from 'layouts/main/partials/navigation-menu';
import { useWidth } from 'modules/common/hooks';
import Logo from 'modules/common/icons/helm-logo.svg';
import { FunctionComponent } from 'react';

export interface NavigationInterface {
  open: boolean;
  handleDrawerClose: () => void;
  handleDrawerOpen: () => void;
}

export const Navigation: FunctionComponent<NavigationInterface> = ({ open, handleDrawerClose, handleDrawerOpen }) => {
  const classes = useNavigationStyles();
  const breakpoint = useWidth();
  return (
    <Drawer
      variant={/xs|sm/.test(breakpoint) ? 'temporary' : 'permanent'}
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      open={open}
    >
      <div className={classes.toolbarIcon}>
        {open ? (
          <SvgIcon component={Logo} sx={{ width: 64, height: 40 }} viewBox="0 0 64 40" />
        ) : (
            <CaretDoubleRight className={classes.drawerRightIcon} onClick={handleDrawerOpen} />
        )}
        {open && <CaretDoubleLeft className={classes.drawerLeftIcon} onClick={handleDrawerClose} />}
      </div>
      <NavigationMenu />
    </Drawer>
  );
};
