import { gql } from '@apollo/client'
import { requestGql } from 'modules/common/utils/request-gql'
import { FetchUserInvitesInterface } from 'modules/users/interfaces/fetch-user-invites.interface';

export interface UserInviteUpdateInterface {
  email?: string;
  firstName?: string;
  lastName?: string;
  locale?: string;
  data?: Record<string, unknown>;
  createdByUserId?: string;
  acceptedByUserId?: string;
}


export interface updateNonActiveUserInviteArgs {
  inviteId: string;
  userInvite?: UserInviteUpdateInterface
}

interface useUpdateNonActiveUserInviteInterface {
  updateNonActiveUserInvite: (args: updateNonActiveUserInviteArgs) => Promise<FetchUserInvitesInterface>
}

export const useUpdateNonActiveUserInvite = (): useUpdateNonActiveUserInviteInterface => {
  const updateNonActiveUserInvite = async ({ inviteId, userInvite}: updateNonActiveUserInviteArgs): Promise<FetchUserInvitesInterface> =>
    requestGql({
      mutation: gql`
      mutation UpdateNonActiveUserInvite($inviteId: ID!, $userInvite: UserInviteDataUpdateArgType!) {
        updateUserInvite(inviteId: $inviteId, userInvite: $userInvite) {
              firstName
              data
              email
              id
              status
              lastName
              }
              }`,
      variables: {
        inviteId,
        userInvite
      }
    })

  return {
    updateNonActiveUserInvite
  }
}