import isArray from 'lodash/isArray';
import omit from 'lodash/omit';
import { updateAuthUserAction } from 'modules/auth/auth.slice';
import { useAuth } from 'modules/auth/hooks';
import { useRouter } from 'modules/common/hooks';
import { useCurrentBusinessPartnerInternal } from 'modules/current-business-partner-selector/hooks';
import { useTimezones } from 'modules/date-time/hooks';
import { AutocompleteCompanyOptionItemInterface } from 'modules/forms/components/autocomplete-company/autocomplete-company.component';
import { useEnhancedFormik, UseEnhancedFormikInterface } from 'modules/forms/hooks';
import { useLocales } from 'modules/locale';
import { LocaleOptionInterface } from 'modules/locale/hooks';
import { BusinessEntityAutocompleteOptionInterface } from 'modules/user-company-management/interfaces/business-entity-autocomplete-option.interface';
import { UserRoleEnum } from 'modules/user-invites/enums';
import { useUserRoles, useUserUpdate } from 'modules/users/hooks';
import { useUpdateNonActiveUserInvite } from 'modules/users/hooks/use-update-non-active-user-invite.hook';
import { UserInterface } from 'modules/users/interfaces';
import { SyntheticEvent, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useEditUserFormSchema } from 'views/users/hooks';

export interface EditUserFormValuesInterface {
  businessPartnerInternals?: { id?: string; name?: string; kunnr?: string; label?: string }[];
  businessEntities?: BusinessEntityAutocompleteOptionInterface[];
  name?: string;
  firstName: string;
  lastName: string;
  email: string;
  department?: string;
  position?: string;
  phone?: string;
  locale?: LocaleOptionInterface | null;
  timezone: string;
  userRoles?: UserRoleEnum[] | string[];
  optedInAt?: Date;
}

export interface UseEditUserFormInterface extends UseEnhancedFormikInterface<EditUserFormValuesInterface> {
  onLocaleChange: (event: SyntheticEvent, option: LocaleOptionInterface) => void;
  onBusinessPartnerInternalChange: (selectedValue: AutocompleteCompanyOptionItemInterface[]) => void;
  onUserRoleChange: {
    (e: React.ChangeEvent<unknown>): void;
    <T_1 = string | React.ChangeEvent<unknown>>(field: T_1): T_1 extends React.ChangeEvent<unknown>
      ? void
      : (e: string | React.ChangeEvent<unknown>) => void;
  };
  onBusinessEntityChange: (selectedBalue: BusinessEntityAutocompleteOptionInterface[]) => void;
  onTimezoneChange: (option: string) => void;
  localeOptions: LocaleOptionInterface[];
  timezoneOptions: string[];
}

export const useEditUserForm = (
  user: UserInterface,
  onSubmitSuccess: () => void,
  onSubmitError: (e: Error) => void,
  userStatusActive: boolean,
  userInviteId?: string
): UseEditUserFormInterface => {
  const dispatch = useDispatch();
  const { user: authUser } = useAuth();
  const { updateUser } = useUserUpdate();
  const router = useRouter();
  const { updateNonActiveUserInvite } = useUpdateNonActiveUserInvite();
  const { localeOptions, localeOption } = useLocales();
  const { isCustomer } = useUserRoles();
  const { resetCurrentBusinessPartnerInternal } = useCurrentBusinessPartnerInternal();

  const timezoneOptions = useTimezones();
  const userBusinessPartners = useMemo(() => authUser.userBusinessPartnerInternals.data, [user]);
  const initialValues = useMemo(
    () => ({
      name: `${user.firstName}` + `${user.lastName ? ` ${user.lastName}` : ''}`,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      department: user.department,
      position: user.position,
      locale: (Boolean(user.locale) && localeOptions.find((l) => l.id === user.locale)) || userInviteId ? localeOption : null,
      timezone: user.timezone,
      phone: user.phone,
      userRoles: user.userUserRoles.data.map((d) => d.userRole.key),
      businessPartnerInternals: user.userBusinessPartnerInternals.data?.filter((d) => d?.businessPartnerInternal).length
        ? user.userBusinessPartnerInternals.data.map((x) => ({
          ...omit(x.businessPartnerInternal, ['__typename', 'name']),
          label: x.businessPartnerInternal.name,
        }))
        : [],
      businessEntities: user?.userBusinessPartnerInternals.data?.filter((d) => d?.businessEntity).length
        ? user?.userBusinessPartnerInternals.data?.map((d) => {
          const entityExists = userBusinessPartners.some(
            (partner) => partner?.businessEntityId === d?.businessEntity?.id,
          );
          return { ...d?.businessEntity, label: d?.businessEntity?.name, disabled: !entityExists };
        })
        : [],
      optedInAt: user.optedInAt,
    }),
    [
      user?.firstName,
      user?.lastName,
      user?.locale,
      user?.timezone,
      user?.userUserRoles,
      user?.userBusinessPartnerInternals,
    ],
  );

  const onSubmit = async (formValues: EditUserFormValuesInterface) => {
    const {
      department,
      locale,
      phone,
      optedInAt,
      position,
      timezone,
      userRoles,
      name,
      businessEntities,
      businessPartnerInternals,

    } = formValues;
    const userName = name.split(' ');
    let firstName = '';
    let lastName = '';
    if (userName?.length > 1) {
      firstName = userName.shift();
      lastName = userName.join(' ');
    } else if (userName?.length === 1) {
      firstName = userName[0];
    }

    if (!userStatusActive) {
      const formatPayload = {
        firstName,
        lastName,
        data: {
          businessPartnerInternalIds: businessPartnerInternals.map(item => item.id),
          businessEntityIds: businessEntities.map(item => item.id),
          userRoleKeys: userRoles,
          phone
        },
        position,
        department,
      }
      try {
        await updateNonActiveUserInvite({ inviteId: userInviteId, userInvite: formatPayload })
        onSubmitSuccess?.();
      } catch (error) {
        onSubmitError?.(error);
        throw error;
      }

      return true;
    } else {
      // selecting latest role of a user, to minimize error due to old data logic. Where user were having many roles rather than one.
      const userRole = [userRoles[0]?.toUpperCase() || ''] as UserRoleEnum[];

      return updateUser(user.id, {
        ...{
          department,
          firstName,
          lastName,
          optedInAt,
          position,
          timezone,
          phone,
          locale: locale?.id,
          ...(!isCustomer && {
            userRoles: userRole,
            businessEntityIds: businessEntities?.map((d) => d?.id),
          }),
          businessPartnerInternalIds: businessPartnerInternals?.map((x) => x.id),
        },
      })
        .then(async (data) => {
          const isCustomerRoleChange =
            UserRoleEnum[userRole[0]] === UserRoleEnum.CUSTOMER_USER
            && user.userUserRoles.data[0].userRole.key !== UserRoleEnum.CUSTOMER_USER;
          const isCurrentUserEdit = authUser.id === user.id;
          // checking if its user is editing his own data
          if (isCurrentUserEdit) {
            await dispatch(updateAuthUserAction(data));
            if (isCustomerRoleChange) {
              // checking if user is editing his own data and role is changed to Customer
              await resetCurrentBusinessPartnerInternal();
              router.reload();
            }
          }
          onSubmitSuccess?.();
        })
        .catch((e) => {
          onSubmitError(e);
          // throwing error so that formik onSubmitError is called instead of onSubmitSuccess
          throw e;
        });
    }

  };

  const formik = useEnhancedFormik<EditUserFormValuesInterface>({
    enableReinitialize: true,
    initialValues,
    onSubmit,
    onSubmitSuccess,
    validationSchema: useEditUserFormSchema(),
  });

  const onLocaleChange = useCallback(
    (_: SyntheticEvent, locale: LocaleOptionInterface) => {
      void formik.setFieldValue('locale', locale);
    },
    [formik.setFieldValue],
  );

  const onTimezoneChange = useCallback(
    (timezone: string) => {
      void formik.setFieldValue('timezone', timezone);
    },
    [formik.setFieldValue],
  );

  const onUserRoleChange = useCallback(
    (e) => {
      const value = e.target.value;
      void formik.setFieldValue('userRoles', isArray(value) ? value : value ? [value] : []);
      void formik.setFieldValue('businessPartnerInternals', []);
      void formik.setFieldValue('businessEntities', []);
    },
    [formik.setFieldValue],
  );

  const onBusinessPartnerInternalChange = useCallback(
    (value) => {
      void formik.setFieldValue('businessPartnerInternals', isArray(value) ? value : value ? [value] : []);
    },
    [formik.setFieldValue],
  );

  const onBusinessEntityChange = useCallback(
    (value) => {
      void formik.setFieldValue('businessEntities', isArray(value) ? value : value ? [value] : []);
    },
    [formik.setFieldValue],
  );

  const { name } = formik.values;

  useEffect(() => {
    const [newFirstName, newLastName] = name.split(' ');

    void formik.setFieldValue('firstName', newFirstName ?? '');
    void formik.setFieldValue('lastName', newLastName ?? '');
  }, [name, formik.setFieldValue]);


  return {
    localeOptions,
    onBusinessPartnerInternalChange,
    onLocaleChange,
    onTimezoneChange,
    onUserRoleChange,
    timezoneOptions,
    onBusinessEntityChange,
    ...formik,
  };
};
