import { gql } from '@apollo/client';
import { OrderEnum } from 'modules/common/enums';
import { PaginationInterface } from 'modules/common/interfaces/pagination.interface';
import { requestGql } from 'modules/common/utils/request-gql';
import { UserRole } from 'modules/user-invites/interfaces/user-invite.interface';

export interface UsersPaginationInterface extends PaginationInterface {
  filter?: Record<string, unknown>;
  order?: {
    order: OrderEnum;
    sort: string;
  };
}

export interface FetchUserRolesInterface {
  totalCount: number;
  data: UserRole[];
}

export interface UseFetchUsersHookInterface {
  fetchUserRoles: (query: UsersPaginationInterface) => Promise<FetchUserRolesInterface>;
}

export const useFetchUserRoles = (): UseFetchUsersHookInterface => {
  const fetchUserRoles = async (query: UsersPaginationInterface): Promise<FetchUserRolesInterface> =>
    requestGql(
      {
        query: gql`
          query userRolesQuery($filter: UserRoleFilterArgType, $limit: Int, $offset: Int) {
            userRoles(filter: $filter, limit: $limit, offset: $offset) {
              totalCount
              data {
                id
                key
                name
                isHelm
                userUserRoles {
                  data {
                    id
                  }
                }
              }
            }
          }
        `,
        variables: {
          filter: query.filter,
          limit: query.limit,
          offset: query.offset,
        },
      },
      null,
      'userRoles',
    );

  return {
    fetchUserRoles,
  };
};
