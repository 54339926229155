import { AppDispatch } from 'configuration/redux/store';
import { OrderEnum } from 'modules/common/enums';
import { UsersSortEnum } from 'modules/users/enums';
import { useFetchUsers } from 'modules/users/hooks';
import { userListSelector } from 'modules/users/selectors';
import {
  setUsersOrder,
  setUsersPagination,
  setUsersRowsCount,
  UserListStateInterface,
} from 'modules/users/users.slice';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export interface UseUsersTableInterface extends UserListStateInterface {
  handlePageChange: (newPage: number, pageSize: number) => void;
  handlePageRowsCountChange: (rowsCount: number) => void;
  handleOrderChange: (sort: UsersSortEnum, order: OrderEnum) => void;
}

export const useUsersTable = (): UseUsersTableInterface => {
  const dispatch = useDispatch<AppDispatch>();
  const { fetchUsers } = useFetchUsers();
  const userList = useSelector(userListSelector);
  const { offset, limit, order } = userList;

  useEffect(() => {
    fetchUsers({
      offset,
      limit,
      order,
    });
  }, [offset, limit, order]);

  const handleOrderChange = (sort: UsersSortEnum, orderDirection: OrderEnum) => {
    dispatch(
      setUsersOrder({
        sort,
        order: orderDirection,
      }),
    );
  };

  const handlePageRowsCountChange = (rowsCount: number) => {
    dispatch(
      setUsersRowsCount({
        rowsCount,
      }),
    );
  };

  const handlePageChange = (newPage: number, pageSize: number) => {
    dispatch(
      setUsersPagination({
        currentPage: newPage,
        limit: pageSize,
      }),
    );
  };

  return {
    ...userList,
    handlePageChange,
    handlePageRowsCountChange,
    handleOrderChange,
  };
};
