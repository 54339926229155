/* eslint-disable @roq/imports-should-follow-conventions */
import { Box } from '@mui/material';
import Container from '@mui/material/Container';
import { useMainLayoutStyles } from 'layouts/main/main.styles';
import { Header } from 'layouts/main/partials/header';
import { Navigation } from 'layouts/main/partials/navigation';
import { UserActivityCheck } from 'layouts/main/partials/user-activity-check/user-activity-check.partial';
import isBoolean from 'lodash/isBoolean';
import { useHasPermission } from 'modules/acl/hooks';
import { MessageTopBar } from 'modules/common/components/message-top-bar';
import { useTopbarMessage } from 'modules/common/components/message-top-bar/hooks/use-topbar-message.hook';
import { useWidth } from 'modules/common/hooks';
import { CurrentBusinessPartnerSidebar, CustomerViewWrapper } from 'modules/current-business-partner-selector';
import { useEnhancedFormik } from 'modules/forms/hooks';
import { useSidebar } from 'modules/layout/hooks';
import Head from 'next/head';
import { memo, ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInvitesFormSchema, useSendInvitesForm } from 'views/user-invite/hooks';
import { UserInviteFormValueInterface } from 'views/user-invite/partials/invite-list/invite-list.partial';
import { UserInviteOverlayPartial } from 'views/user-management/partials/user-invites-overlay';


export interface MainLayoutInterface {
  title?: string;
  children?: ReactNode;
  breadcrumbs?: ReactNode;
  noPadding?: boolean;
  openInviteOverlay?: boolean;
  setInviteOverlay?: (val?: boolean) => void;
  showCompanySelector?: boolean;
  isInCardCompanyView?: boolean;
}

export const MainLayout = ({
  title,
  breadcrumbs,
  children,
  noPadding,
  openInviteOverlay,
  showCompanySelector,
  isInCardCompanyView,
  setInviteOverlay,
}: MainLayoutInterface): ReactElement => {
  const classes = useMainLayoutStyles();
  const { t } = useTranslation();
  const { authorized } = useHasPermission('notifications.read');
  const { open: handleDrawerOpen, close: handleDrawerClose, isOpen: drawerOpen } = useSidebar();
  const { showTopbarMessage, hideTopbarMessage } = useTopbarMessage();
  const [openCustInvitesOverlay, setOpenInviteOverlay] = useState(false);
  const breakpoint = useWidth();
  const initialValues: UserInviteFormValueInterface = useMemo(
    () => ({
      businessEntities: [],
      invitedCompanies: [],
      userRole: null,
      invites: [
        {
          name: '',
          email: '',
        },
      ],
    }),
    [],
  );

  const MemoizedCurrentBusinessPartnerSidebar = memo(function MemoizedCurrentBusinessPartnerSidebar({authorized: isAuthorized}: {authorized: boolean}) {
    return isAuthorized ? <CurrentBusinessPartnerSidebar /> : null
  }, (prevProp, currentProp) => prevProp.authorized !== currentProp.authorized);

  useEffect(() => {
    if (isBoolean(openInviteOverlay)) {
      setOpenInviteOverlay(openInviteOverlay)
    }
  }, [openInviteOverlay])


  useEffect(() => {
    // syncing the parent state if available
    if (!!setInviteOverlay) {
      setInviteOverlay(openCustInvitesOverlay)
    }
  }, [openCustInvitesOverlay])

  const { handleSendInvite: onSubmit, errorCount, successCount } = useSendInvitesForm();

  const inviteWasSentGlossary =
    successCount > 1 ? 'invite-was-sent-successfully_plural' : 'invite-was-sent-successfully';
  const formik = useEnhancedFormik<UserInviteFormValueInterface>({
    initialValues,
    onSubmit,
    validationSchema: useInvitesFormSchema(),
    onSubmitSuccess: ({ successCount: success, errorCount: error }: { successCount: number; errorCount: number }) => {
      if (success) {
        showTopbarMessage({ title: t(inviteWasSentGlossary, { count: success }), severity: 'success' });
      }
      if (!error) {
        setOpenInviteOverlay(false);
        formik.resetForm();
      }
    },
    validateOnMount: false,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmitError: (error) => {
      hideTopbarMessage();
      if (error.message !== 'client') {
        setOpenInviteOverlay(false);
        showTopbarMessage({ title: t('invite-failed', { count: errorCount }), severity: 'error' });
      }
    },
  });

  const handleOpenOverlay = () => {
    setOpenInviteOverlay(true);
  };


  // Need to investigate was causing re-rendering issue for sidebar
  useEffect(() => {
    const handleDrawerDefaultState = () => {
      if (/xs|sm/.test(breakpoint)) {
        handleDrawerClose()
      } else {
        handleDrawerOpen()
      }
    }
    window.addEventListener('resize', handleDrawerDefaultState);

    return () => window.removeEventListener('resize', handleDrawerDefaultState)
  }, [])

  return (
    <UserActivityCheck>
      <Head>
        <title>{title}</title>
      </Head>

      <div className={classes.root}>
        <Header
          open={drawerOpen}
          handleDrawerOpen={handleDrawerOpen}
          title={title}
          handleOpenOverlay={handleOpenOverlay}
        />
        <Navigation open={drawerOpen} handleDrawerClose={handleDrawerClose} handleDrawerOpen={handleDrawerOpen} />
        {/* {authorized && !isLoading && <NotificationsSidebar />} */}
        <MemoizedCurrentBusinessPartnerSidebar authorized={authorized} />
        <main className={classes.content} id="main-layout">
          <div className={/xs|sm/.test(breakpoint) ? classes.appBarSpacer : classes.appBarSpacerLarger}
          />
          <Container
            className={[classes.container, noPadding ? classes.containerNoPadding : ''].join(' ')}
            maxWidth={false}
          >
            <div>
              <MessageTopBar />
            </div>
            {breadcrumbs && <div className={classes.breadcrumbsContainer}>{breadcrumbs}</div>}
            <Box marginTop={1}>{children}</Box>
            {showCompanySelector && <Box >
              <CustomerViewWrapper inCard={isInCardCompanyView} />
            </Box>}
          </Container>
        </main>
      </div>
      <UserInviteOverlayPartial
        formik={formik}
        open={openCustInvitesOverlay}
        onClose={() => {
          setOpenInviteOverlay(false);
        }}
        onSuccess={() => {
          setOpenInviteOverlay(false);
        }}
      />
    </UserActivityCheck>
  );
};
