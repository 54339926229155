import { hasPermission } from 'modules/acl/utils';
import { useUserRoles } from 'modules/users/hooks';
import { useEffect, useState } from 'react';

interface UseHasPermissionResponseInterface {
  isLoading: boolean;
  authorized: boolean;
}

export const useHasPermission = (permission: string): UseHasPermissionResponseInterface => {
  const { roles, isLoading } = useUserRoles();
  const [authorized, setAuthorized] = useState(null);

  useEffect(() => {
    if (!isLoading) {
      if (roles?.length) {
        const result = hasPermission(permission, roles)
        if (result) {
          setAuthorized(true)
        } else {
          setAuthorized(false)
        }
      } else {
        setAuthorized(false)
      }
    }
  }, [roles, isLoading])


  return { isLoading, authorized };
}