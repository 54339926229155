import { Box, Button, CircularProgress } from '@mui/material';
import {
  CurrentBusinessPartnerInternalInterface,
  useCurrentBusinessPartnerInternal,
} from 'modules/current-business-partner-selector/hooks/use-current-business-partner-internal.hook';
import { AutocompleteCompanySingle } from 'modules/forms/components/autocomplete-company';
import { AutocompleteCompanyOptionItemInterface } from 'modules/forms/components/autocomplete-company/autocomplete-company.component';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const CurrentBusinessPartnerSelector = (): React.ReactElement => {
  const { t } = useTranslation();
  const { currentBusinessPartnerInternal, changeCurrentBusinessPartnerInternal, isLoading } =
    useCurrentBusinessPartnerInternal();
  const [formValue, setFormValue] = useState<CurrentBusinessPartnerInternalInterface>(currentBusinessPartnerInternal);

  const handleChange = (value: AutocompleteCompanyOptionItemInterface) => {
    if (value) {
      setFormValue({
        id: value.id,
        name: value.label,
        kunnr: value.kunnr,
      });
    }
  };

  const handleCommitChange = () => {
    if (formValue) {
      void changeCurrentBusinessPartnerInternal({
        id: formValue.id,
        name: formValue.name,
        kunnr: formValue.kunnr,
      });
    }
  };

  useEffect(() => {
    setFormValue(currentBusinessPartnerInternal);
  }, [currentBusinessPartnerInternal]);

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <AutocompleteCompanySingle
          placeholder={t('company')}
          value={formValue || null}
          onChange={handleChange}
          name={'currentBusinessPartner'}
        />
        <Box>
          <Button
            disabled={isLoading}
            variant="contained"
            color="primary"
            size='large'
            sx={{ height: 43 }}
            onClick={handleCommitChange}
          >
            {t('apply')}
            {isLoading && <CircularProgress color="inherit" size={16} />}
          </Button>
        </Box>
      </Box>
    </>
  );
};
