import { Box, Divider, Drawer, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useActionOverlayStyles } from 'modules/action-overlay/components/action-overlay/action-overlay.styles';
import { ActionOverlaySettingsInterface } from 'modules/action-overlay/interfaces/action-overlay-settings.interface';
import { ConfirmationModal } from 'modules/common/components/confirmation-modal';
import { ConfirmationModalPropsInterface } from 'modules/common/components/confirmation-modal/confirmation-modal.component';
import { useTranslation, useWidth } from 'modules/common/hooks';
import CloseIcon from 'modules/common/icons/close-icon.svg';
import React, { ReactElement, useMemo, useState } from 'react';

// eslint-disable-next-line @roq/correct-export-name-components-layouts-actions
export interface ActionOverlayProps<TParams> extends Partial<ActionOverlaySettingsInterface<TParams>> {
  open?: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  onError?: (e: Error) => void;
  children?: JSX.Element | JSX.Element[];
  withToolbar?: boolean;
}

export const ActionOverlay = <TParams extends unknown>({
  open,
  config,
  params,
  onClose,
  children,
  withToolbar = true,
}: ActionOverlayProps<TParams>): ReactElement => {
  const { t } = useTranslation();
  const theme = useTheme();
  const screenWidth = useWidth();

  const classes = useActionOverlayStyles(
    useMemo(
      () => ({
        size: config?.size ?? 'medium',
      }),

      [config?.size],
    ),
  );
  const [closeConfirmationVisible, setCloseConfirmationVisible] = useState(false);
  const [formDirty, setFormDirty] = useState(false);

  const handleOverlayClose = () => {
    if (formDirty && (config?.closeConfirmationModal ?? true)) {
      setCloseConfirmationVisible(true);
    } else {
      setFormDirty(false);
      onClose();
    }
  };

  const handleCloseConfirm = () => {
    setCloseConfirmationVisible(false);
    setFormDirty(false);
    onClose();
  };

  const handleCloseCancel = () => setCloseConfirmationVisible(false);

  const ModalComponent = config?.modalComponent || Drawer;
  const modalProps = config?.modalProps || { anchor: 'right', classes: classes.modal };
  const top = `${parseInt(theme.sizes.header as string, 10) + 11 + (/xs|sm/.test(screenWidth) ? 40 : 0)}px`;

  return (
    <>
      {closeConfirmationVisible && (
        <ConfirmationModal
          title={t('action-overlay.close-confirmation-modal.title')}
          message={t('action-overlay.close-confirmation-modal.message')}
          cancelButtonLabel={t('action-overlay.close-confirmation-modal.cancel')}
          confirmButtonLabel={t('action-overlay.close-confirmation-modal.confirm')}
          {...(config?.closeConfirmationModalProps?.(params) as ConfirmationModalPropsInterface)}
          onConfirm={handleCloseCancel}
          onCancel={handleCloseConfirm}
          onClose={handleCloseCancel}
        />
      )}
      <ModalComponent
        {...modalProps}
        open={open}
        onClose={handleOverlayClose}
        sx={{
          ...(withToolbar && {
            top,
            '& > .MuiBackdrop-root': {
              top,
            },
            '& > .MuiDrawer-paper': {
              top,
              height: `calc(100% - ${top})`,
              borderRadius: '0px !important'
            },
          }),
        }}
      >
        <>
          {!config ? <Divider variant='fullWidth' /> : null}
          <Box className={classes.body}>
            {config && (
              <>
                <Box sx={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 129,
                  background: 'white',
                }}>
                  <Divider variant='fullWidth' />
                  <Box className={classes.header}>
                    <Box>
                      {config.title && (
                        <Typography variant="h3" className={classes.title}>
                          {config.title}
                        </Typography>
                      )}
                      {config.subTitle && (
                        <Typography variant="h4" className={classes.subTitle}>
                          {config.subTitle}
                        </Typography>
                      )}
                    </Box>
                    <Box>
                      <IconButton onClick={handleOverlayClose} sx={{ p: 0, mt: '-6px' }}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
                  <Box className={classes.content}>{children}</Box>
                </>
          )}
              </Box>
          </>
        </ModalComponent>
      </>
      );
};
