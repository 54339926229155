import { entityConfig } from 'modules/acl/config/entity.config';
import { UserRoleEnum } from 'modules/user-invites/enums';

export const getEntityConfig = (
  entityName: string,
): {
  read: {
    route: string;
    roles: UserRoleEnum[];
  }
} => {
  const config = entityConfig;
  return config[entityName];
}