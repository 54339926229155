import { Box, Card, CardContent, Divider, Skeleton, Stack, Typography } from '@mui/material';
import { Buildings } from '@phosphor-icons/react';
import { CurrentBusinessPartnerSelector } from 'modules/current-business-partner-selector';
import { useCurrentBusinessPartnerInternal } from 'modules/current-business-partner-selector/hooks';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface CustomerViewWrapperProps {
  inCard?: boolean;
  children?: ReactNode;
  centered?: boolean;
  height?: number | string;
}

export const CustomerViewWrapper = ({
  inCard = false,
  centered = false,
  height,
  children,
}: CustomerViewWrapperProps): React.ReactElement => {
  const { t } = useTranslation();
  const { isLoading, currentBusinessPartnerInternal } = useCurrentBusinessPartnerInternal(true);
  if (isLoading) {
    return (
      <Card>
        <CardContent sx={{ paddingY: 3 }}>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </CardContent>
      </Card>
    );
  }

  if (currentBusinessPartnerInternal?.id && children) {
    return <>{children}</>;
  }

  const content = (
    <Box sx={{ width: 500, maxWidth: '100%',}}>
      <Box>
        <Typography variant="subtitle1" color="GrayText" marginBottom={'12px'} fontSize={'15px'} lineHeight={'24px'} letterSpacing={'0.17px'}>
          {t('select-company-see-data')}
        </Typography>
      </Box>

      <CurrentBusinessPartnerSelector />
    </Box>
  );

  if (!centered) {
    return (
      <Card sx={{ height, margin: inCard ? '12px 0px 0px 0px !important' : '24px !important' }}>
        <CardContent sx={{ padding: '24px' }}>
          <Typography
            sx={{ display: 'flex', alignItems: 'center' }}
            variant="h2"
            color="black"
            fontWeight={900}
            fontSize={20}
          >
            <Buildings />
            &nbsp; {t('company-view')}
          </Typography>
        </CardContent>
        <Divider />
        <CardContent sx={{ padding: '24px' }}>{content}</CardContent>
      </Card>
    );
  }

  if (!inCard && centered) {
    return (
      <Box sx={{ height: height || 500, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {content}
      </Box>
    );
  }
};
