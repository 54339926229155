/* eslint-disable @roq/no-eslint-disable */
/* eslint-disable @roq/name-of-class-and-function-rule */
/* file name is conflicting with other rule */
// import { publicConfig } from 'configuration/app';
import { initializeAction } from 'modules/auth/auth.slice';
import { useAuth } from 'modules/auth/hooks';
import { Loader } from 'modules/common/components/loader';
import { useRouter } from 'modules/common/hooks';
import { Session } from 'next-auth';
import { useSession } from 'next-auth/react';
import React, { ComponentType, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import routes from 'routes';

export interface WithAuthHocParamsInterface {
  redirect?: boolean;
  redirectTo?: string;
  redirectIfAuthenticated?: boolean;
  twoFactorRedirection?: boolean;
}

export function withAuth<Props>({
  redirect = true,
  redirectTo,
  redirectIfAuthenticated = false,
}: WithAuthHocParamsInterface = {}): (WrappedComponent: ComponentType<Props>) => ComponentType<Props> {
  const realRedirectTo = redirectTo || (redirectIfAuthenticated ? routes.home : routes.login);

  return (WrappedComponent: ComponentType<Props>): ComponentType<Props> => {
    const WithAuth = (props: Props) => {
      const router = useRouter();
      const dispatch = useDispatch();
      const { isInitializing, user, accessToken, sessionUpdatedAt } = useAuth();
      const { data: session, status } = useSession();
      const isLoading = useMemo(() => status === 'loading', [status]);

      const shouldRedirect = useMemo(
        // we need both access token and user to consider current session as authenticated
        () => !isInitializing && Boolean(redirectIfAuthenticated) === Boolean(accessToken && user),
        [isInitializing, user, accessToken],
      );

      useEffect(() => {
        // we check if session was really reloaded by comparing updatedAt
        if (!isLoading && session !== undefined && sessionUpdatedAt !== (session as Session & { updatedAt?: number })?.updatedAt) {
          dispatch(initializeAction({ session }));
        }
      }, [isLoading, session, sessionUpdatedAt]);

      useEffect(() => {
        // redirect only after first session load and when user is authenticated and currentPath is not 'login'.
        if (redirect && shouldRedirect && (realRedirectTo !== routes.login)) {
          const redirectPath = (router.query?.redirect && realRedirectTo === routes.home) ? `/${router.query?.redirect}` as string : routes.home;
          void router.replace(redirectPath);
        }
      }, [shouldRedirect]);

      useEffect(() => {
        // only when session is not loading and path is of login and no accessToken or user is present.
        if (!isInitializing && (!accessToken || !user) && realRedirectTo === routes.login) {
          const currentPath = location.pathname;
          const isValidPath = Object.values(routes).some(path => currentPath.match(path));
          const redirectPathWithQuery = isValidPath ? `/${routes.login}?redirect=${currentPath?.replace(/\//, '')}` : routes.login;
          void router.replace(redirectPathWithQuery);
        }
      }, [isInitializing])

      // only show loader when initializing session (first time)
      if (isInitializing || shouldRedirect) {
        return <Loader />;
      }

      return <WrappedComponent {...props} />;
    };
    return WithAuth;
  };
}
