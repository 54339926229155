import { gql } from '@apollo/client';

export const fetchNotificationNextPageQuery = gql`
  query notificationWebs($filter: NotificationInAppFilterArgType, $limit: Int,  $order: NotificationInAppOrderArgType) {
    notificationsInAppForCurrentUser(limit: $limit, order: $order, filter: $filter) {
      totalCount
      data {
        id
        title
        content
        locale
        createdAt
        read
        icon
      }
    }
  }
`;
