import { gql } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { OrderEnum } from 'modules/common/enums';
import { PaginationInterface } from 'modules/common/interfaces/pagination.interface';
import { requestGql } from 'modules/common/utils/request-gql';
import { CustomerUserInterface } from 'modules/user-company-management/interfaces';
import { CustomerUserSortEnum } from 'views/user-company-management/enums';

export interface TablePaginationInterface extends PaginationInterface {
  filter?: Record<string, unknown>;
  order?: {
    order: OrderEnum;
    sort: CustomerUserSortEnum;
  };
}

export interface UseFetchCustomerUsersHookInterface {
  fetchCustomerUsers: (
    isHelm: boolean,
    query?: TablePaginationInterface,
  ) => Promise<{
    data: CustomerUserInterface[];
    totalCount: number;
  }>;
}

export const useFetchCustomerUsers = (): UseFetchCustomerUsersHookInterface => {
  const fetchCustomerUsers = (
    isHelm: boolean,
    query?: TablePaginationInterface,
  ): Promise<{
    data: CustomerUserInterface[];
    totalCount: number;
  }> => {
    const customerUsersQuery = gql`
      query usersQuery(
        $filter: UserFilterArgType
        $limit: Int
        $offset: Int
        $order: UserOrderArgType
        $search: UserSearchArgType
      ) {
        customerUsers(filter: $filter, limit: $limit, offset: $offset, order: $order, search: $search) {
          totalCount
          data {
            id
            email
            firstName
            lastName
            locale
            active
            position
            department
            userBusinessPartnerInternal {
              businessPartnerInternal {
                name
              }
            }
            userUserRoles {
              data {
                id
                userRoleId
                userRole {
                  id
                  name
                }
              }
            }
          }
        }
      }
    `;

    const helmUsersQuery = gql`
      query usersQuery(
        $filter: UserFilterArgType
        $limit: Int
        $offset: Int
        $order: UserOrderArgType
        $search: UserSearchArgType
      ) {
        users(filter: $filter, limit: $limit, offset: $offset, order: $order, search: $search) {
          totalCount
          data {
            id
            email
            firstName
            lastName
            locale
            active
            position
            department
            userBusinessPartnerInternal {
              businessPartnerInternal {
                name
              }
            }
            userUserRoles {
              data {
                id
                userRoleId
                userRole {
                  id
                  name
                }
              }
            }
          }
        }
      }
    `;

    return requestGql(
      {
        query: isHelm ? helmUsersQuery : customerUsersQuery,
        variables: !isEmpty(query)
          ? {
              filter: query.filter,
              limit: query.limit,
              offset: query.offset,
              order: query.order,
            }
          : {},
      },
      null,
      isHelm ? 'users' : 'customerUsers',
    );
  };

  return {
    fetchCustomerUsers,
  };
};
