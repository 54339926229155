import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ThemeEnum } from 'modules/theme/enums';

const drawerWidth = 240;

export const useNavigationStyles = makeStyles((theme: Theme) => ({
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '14px 8px',
    minHeight: '68px !important',
    boxShadow: theme.palette.mode === ThemeEnum.LIGHT ? `inset 0px -1px 0px #0f6a7b` : `inset 0px -1px 0px #293445`,
    '& svg': {
      fill: theme.palette.primary.contrastText,
      boxSizing: 'content-box',
      padding: '0px 8px',
      '&:hover': {
        fill: theme.palette.common.white,
      },
    },
  },
  drawerPaper: {
    background: theme.palette.mode === ThemeEnum.LIGHT ? theme.palette.primary.main : '#1e293b',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    borderRadius: '0 !important',
    border: 'none !important',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    zIndex: theme.zIndex.drawer + 1,
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    width: '60px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  openDrawerIcon: {
    paddingRight: theme.spacing(3),
  },
  drawerLeftIcon: {
    cursor: 'pointer',
    padding: '0',
  },
  drawerRightIcon: {
    margin: 'auto',
    cursor: 'pointer',
    padding: '0',
  },
}));
