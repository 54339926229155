/* eslint-disable @roq/imports-should-follow-conventions */
/* eslint-disable @roq/no-invalid-resource-location */
import { Paper } from '@mui/material';
import { ActionOverlay, ActionOverlayProps } from 'modules/action-overlay/components/action-overlay';
import { UseEnhancedFormikInterface } from 'modules/forms/hooks';
import { useUserRoles } from 'modules/users/hooks';
import { FunctionComponent, ReactElement } from 'react';
import {
  InviteListPartial,
  UserInviteFormValueInterface,
} from 'views/user-invite/partials/invite-list/invite-list.partial';

interface InviteUserOverlayInterface extends ActionOverlayProps<null> {
  formik: UseEnhancedFormikInterface<UserInviteFormValueInterface>;
}

export const UserInviteOverlay: FunctionComponent<InviteUserOverlayInterface> = (props) => {
  const { formik, onClose } = props;
  const { isCustomer } = useUserRoles();
  return (
    <Paper elevation={0}>
      <ActionOverlay
        config={{
          title: isCustomer ? 'Invite Your Team' : 'Invite New Users',
          size: 'small',
        }}
        {...props}
      >
        <InviteListPartial onClose={onClose} formik={formik} />
      </ActionOverlay>
    </Paper>
  );
};

interface InviteUserOverlayPartialInterface extends ActionOverlayProps<null> {
  formik: UseEnhancedFormikInterface<UserInviteFormValueInterface>;
}

export const UserInviteOverlayPartial = (props: InviteUserOverlayPartialInterface): ReactElement => {
  const { onClose, formik } = props;

  return <UserInviteOverlay {...props} onClose={onClose} formik={formik} />;
};
