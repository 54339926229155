import { gql } from '@apollo/client';
import { requestGql } from 'modules/common/utils/request-gql';
import { CustomEmailInterface } from 'modules/user-company-management/interfaces';

export interface UpdateCustomEmailInterface {
  id: string;
  email: string;
  businessPartnerInternalId: string;
}

export interface UseUpdateCustomEmailHookInterface {
  updateCustomEmail: (args: UpdateCustomEmailInterface) => Promise<CustomEmailInterface>;
}

export const useHandleUpdateCustomEmail = (): UseUpdateCustomEmailHookInterface => {
  const updateCustomEmail = (args: UpdateCustomEmailInterface): Promise<CustomEmailInterface> =>
    requestGql(
      {
        mutation: gql`
          mutation updateCustomEmail($customEmail: CustomEmailCreateDto!, $id: ID!) {
            updateCustomEmail(id: $id, customEmail: $customEmail) {
              email
              id
            }
          }
        `,
        variables: {
          id: args.id,
          customEmail: {
            email: args.email,
            businessPartnerInternalId: args.businessPartnerInternalId,
          },
        },
      },
      null,
      'updateCustomEmail',
    );

  return {
    updateCustomEmail,
  };
};
