import { Alert, AlertProps, AlertTitle, SxProps, Theme } from '@mui/material';
import { CheckCircle, Info, Warning, WarningCircle } from '@phosphor-icons/react';
import { useEffect, useState } from 'react';

interface MessageBarPropsInterface extends AlertProps {
  showClose?: boolean;
  onClose?: () => void;
  sx?: SxProps<Theme>;
}

const getIcon = (severity: string) => {
  switch (severity) {
    case 'success':
      return <CheckCircle size={22} color="#11AE59" />
    case 'info':
      return <Info size={22} color="blue" />
    case 'warning':
      return <Warning size={22} color="yellow" />
    case 'error':
      return <WarningCircle size={22} strokeWidth={'5px'} stroke='#E02547' color="#E02547" />
    default: 
      return <CheckCircle size={22} color="green" />
  }
}

export const MessageBar = (props: MessageBarPropsInterface): React.ReactElement => {
  const [isClosed, setIsClosed] = useState(false);
  const { onClose, showClose, severity ,...alertProps } = props;
  useEffect(() => {
    setIsClosed(false);
  }, [props.title]);

  const handleClose = () => {
    setIsClosed(true);
    onClose?.();
  };

  if (isClosed) {
    return <></>;
  }
  
  const icon = getIcon(severity)


  return (
    <Alert sx={{ mb: 2, ...props.sx }} {...alertProps} severity={severity} icon={icon} onClose={onClose || showClose ? handleClose : null}>
      <AlertTitle sx={{
        fontSize: '16px',
        fontWeight: '600',
        lineHeight: '24px',
        letterSpacing: '0.15px',
        wordWrap: 'break-word',
        margin: '0px'
      }}>{props.title}</AlertTitle>
      {props.children}
    </Alert>
  );
};
