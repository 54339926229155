import { getEntityConfig } from 'modules/acl/utils/get-entity-config';
import { UserRoleEnum } from 'modules/user-invites/enums';

export const hasPermission = (permission: string, userRoles: UserRoleEnum[]): boolean => {
  const [entityName, permissionName] = permission.split('.');
  const configRoute = getEntityConfig(entityName);

  if (!configRoute) {
    return false;
  }

  const entityPermissionRoles = configRoute[permissionName]?.roles || [];
  const isRoleDefined = userRoles?.find((item) => entityPermissionRoles.includes(item));

  return !!isRoleDefined;
};
