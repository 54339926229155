import MenuIcon from '@mui/icons-material/Menu';
import { Box, Divider, Stack } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { useHeaderStyles } from 'layouts/main/partials/header/header.styles';
import { HeaderMenu } from 'layouts/main/partials/header-menu';
import { CustomTooltip } from 'modules/common/components/custom-tooltip/custom-tooltip.component';
import { useWidth } from 'modules/common/hooks';
import { truncateText } from 'modules/common/utils';
import { useCurrentBusinessPartnerInternal, useCurrentBusinessPartnerSidebar } from 'modules/current-business-partner-selector/hooks';
import { useMessageCenterProvider } from 'modules/message-center/hooks';
import { FunctionComponent } from 'react';

const CompanyDisplayBox: FunctionComponent = () => {
  const { currentBusinessPartnerInternal } = useCurrentBusinessPartnerInternal();
  const { toggleBusinessPartnerSidebar } = useCurrentBusinessPartnerSidebar();
  return (
    <>
      <Divider variant='fullWidth' />
      <Stack flexDirection={'row-reverse'} height={'46px'}>
        <Box sx={{ padding: '12px 24px', width: '100%', textAlign: 'end' }} onClick={() => toggleBusinessPartnerSidebar() } >
          {currentBusinessPartnerInternal?.id ? (
            <>
              <CustomTooltip title={currentBusinessPartnerInternal.name}>
                <Typography variant="subtitle2" color={'primary'} fontWeight={600}>
                  {truncateText(currentBusinessPartnerInternal.name, 30)}
                </Typography>
              </CustomTooltip>
            </>
          ) : (
            <></>
          )}
        </Box>
      </Stack>
    </>
  )
}

export type HeaderProps = {
  title?: string | undefined | null;
  open: boolean;
  handleDrawerOpen: () => void;
  handleOpenOverlay: () => void;
};

export const Header: FunctionComponent<HeaderProps> = ({ title, open, handleDrawerOpen, handleOpenOverlay }) => {
  const classes = useHeaderStyles();
  const width = useWidth();
  useMessageCenterProvider();
  const isOverview = title?.toLowerCase().match('overview');
  const isSmallDevice = /xs|sm/.test(width);

  return (
    <AppBar
      elevation={0}
      position="fixed"
      className={clsx(
        classes.appBar,
        isSmallDevice ? classes.appBarSmall : classes.appBarLarge,
        open && classes.appBarOpen,
      )}
    >
      <Toolbar className={classes.toolbar}>
        {isSmallDevice ? 
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
        >
          <MenuIcon /> 
        </IconButton>
          : null}
        <Typography className={classes.title}>{title}</Typography>
        <HeaderMenu handleOpenOverlay={handleOpenOverlay} />
      </Toolbar>
      {isSmallDevice ?
        <CompanyDisplayBox />
        : null
      }
      {isOverview ? <Divider variant='fullWidth' /> : null}
    </AppBar>
  );
};
