import { gql } from '@apollo/client';
import { requestGql } from 'modules/common/utils/request-gql';
import { CustomEmailInterface } from 'modules/user-company-management/interfaces';

export interface CreateCustomEmailInterface {
  email: string;
  businessPartnerInternalId: string;
}

export interface UseCreateCustomEmailHookInterface {
  createCustomEmail: (args: CreateCustomEmailInterface) => Promise<CustomEmailInterface>;
}

export const useHandleCreateCustomEmail = (): UseCreateCustomEmailHookInterface => {
  const createCustomEmail = (args: CreateCustomEmailInterface): Promise<CustomEmailInterface> =>
    requestGql(
      {
        mutation: gql`
          mutation createCustomEmail($customEmail: CustomEmailCreateDto!) {
            createCustomEmail(customEmail: $customEmail) {
              id
              email
              businessPartnerInternalId
            }
          }
        `,
        variables: {
          customEmail: {
            email: args.email,
            businessPartnerInternalId: args.businessPartnerInternalId,
          },
        },
      },
      null,
      'createCustomEmail',
    );

  return {
    createCustomEmail,
  };
};
