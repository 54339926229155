import { gql } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { OrderEnum } from 'modules/common/enums';
import { PaginationInterface } from 'modules/common/interfaces/pagination.interface';
import { requestGql } from 'modules/common/utils/request-gql';
import { CompanyInterface } from 'modules/user-company-management/interfaces';
import { CompanySortEnum } from 'views/user-company-management/enums';

export interface TablePaginationInterface extends PaginationInterface {
  order?: {
    order: OrderEnum;
    sort: CompanySortEnum;
  };
  filter?: Record<string, unknown>;
}

export interface UseFetchCompaniesHookInterface {
  fetchCompanies: (query?: TablePaginationInterface) => Promise<{
    data: CompanyInterface[];
    totalCount: number;
  }>;
}

export const useFetchCompanies = (): UseFetchCompaniesHookInterface => {
  const fetchCompanies = (
    query?: TablePaginationInterface,
  ): Promise<{
    data: CompanyInterface[];
    totalCount: number;
  }> =>
    requestGql(
      {
        query: gql`
          query BusinessPartnerInternal(
            $filter: BusinessPartnerInternalFilterArgType
            $limit: Int
            $offset: Int
            $order: BusinessPartnerInternalOrderArgType
          ) {
            businessPartnerInternals(filter: $filter, limit: $limit, order: $order, offset: $offset) {
              data {
                kunnr
                id
                name
                registeredUsersCount
                userBusinessPartnerInternals {
                  data {
                    id
                  }
                  totalCount
                }
              }
              totalCount
            }
          }
        `,
        variables: !isEmpty(query)
          ? {
              filter: query.filter,
              limit: query.limit,
              offset: query.offset,
              order: query.order,
            }
          : {},
      },
      null,
      'businessPartnerInternals',
    );

  return {
    fetchCompanies,
  };
};
