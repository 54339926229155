import { publicConfig } from 'configuration/app';
import { useLogout } from 'modules/auth/hooks';
import { useSession } from 'next-auth/react';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { ReactElement } from 'react-markdown/lib/react-markdown';

function debounceLeading<T extends (...args: unknown[]) => ReturnType<T>>(callback: T, timeout = 300) {
  let timer: NodeJS.Timeout | undefined;
  return (...args: Parameters<T>) => {
    if (timer) {
      return;
    }
    timer = setTimeout(() => {
      callback(...args);
      timer = undefined;
    }, timeout);
  };
}

const debounceUpdate = debounceLeading((callback: () => void) => {
  callback();
}, 10000);

const _storageKey = '_lastActivity';
// on mount, we will listen to several possible "interactive" events
const windowEvents: WindowActivityEvent[] = ['focus', 'scroll', 'click', 'mousemove'];

function storage() {
  return global.window !== undefined ? window.localStorage : null;
}

function activity() {
  return new Date().getTime();
}

const parseLastActivityString = (activityStr?: string | null) => {
  if (!activityStr) return null;

  const lastActivity = +activityStr;

  const now = activity();

  if (lastActivity == null || lastActivity > now || lastActivity <= 0 || Number.isNaN(lastActivity)) {
    return null;
  }

  return lastActivity;
};

const initLastActivity = () => {
  const now = activity();

  const lastActivityStr = storage()?.getItem(_storageKey);

  const lastActivity = parseLastActivityString(lastActivityStr);

  return lastActivity == null ? now : lastActivity;
};

type WindowActivityEvent = keyof WindowEventMap;

interface AutoLogoutProviderProps {
  timeoutMs?: number;
  timeoutCheckMs?: number;
  debug?: boolean;
  requireSession?: boolean;
  children: ReactNode | ReactElement
}

export const UserActivityCheck = ({
  timeoutMs = publicConfig.activityTimeInterval, // Inactivity time
  timeoutCheckMs = 10000, // Time out to check
  debug = true,
  children,
}: AutoLogoutProviderProps): ReactElement => {
  const [lastActivity, setLastActivity] = useState(() => initLastActivity());
  const { data: session, status } = useSession();
  const { handleLogout } = useLogout()
  const isUserInactive = useCallback(() => {
    const now = activity();
    if (status === 'authenticated') {
      const expiry = new Date(session?.expires).getTime();
      if (now > expiry) {
        storage()?.removeItem(_storageKey);
        void handleLogout()
        return true;
      }
    }

    if (lastActivity + timeoutMs < now) {
      storage()?.removeItem(_storageKey);
      void handleLogout()
      return true;
    }
    return false;
  }, [debug, lastActivity, session?.expires, status, timeoutMs]);

  const onUserActivity = useCallback(() => debounceUpdate(() => {
    const now = activity();
    storage()?.setItem(_storageKey, now.toString());
    setLastActivity(now);
  }), [debug]);

  useEffect(() => {
    if (status === 'loading') return;
    if (status === 'unauthenticated') return;

    // no timer has been initialized
    if (timeoutMs === null) {
      return;
    }

    // if user is already inactive, do not init
    if (isUserInactive()) {
      return;
    }

    const onStorage = ({ key, storageArea, newValue }: StorageEvent) => {
      if (key === _storageKey && storageArea === storage()) {
        const la = parseLastActivityString(newValue);

        if (la !== null) {
          setLastActivity(la);
        }
      }
    };

    const onTimerElapsed = () => isUserInactive();

    windowEvents.forEach((eventName) => {
      window.addEventListener(eventName, onUserActivity, false);
    });

    // we will use localStorage to determine activity
    window.addEventListener('storage', onStorage, false);

    // initialize an interval to check activity
    const intervalId = window.setInterval(onTimerElapsed, timeoutCheckMs);

    return () => {
      // detach and destroy listeners on deconstructor
      windowEvents.forEach((eventName) => {
        window.removeEventListener(eventName, onUserActivity, false);
      });

      window.removeEventListener('storage', onStorage, false);

      window.clearInterval(intervalId);
    };
  }, [debug, status, timeoutCheckMs, timeoutMs, isUserInactive, onUserActivity]);

  return children as ReactElement;
}