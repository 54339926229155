import { Box, Stack } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { HourglassLow } from '@phosphor-icons/react';
import { useNavigationMenuItemStyles } from 'layouts/main/partials/navigation-menu-item/navigation-menu-item.styles';
import { CustomTooltip } from 'modules/common/components/custom-tooltip/custom-tooltip.component';
import { RoqLink } from 'modules/common/components/roq-link';
import { useWidth } from 'modules/common/hooks';
import { RouteUrlInterface } from 'modules/common/interfaces';
import { resolveValue } from 'modules/common/utils/resolve-value';
import { useSidebar } from 'modules/layout/hooks';
import { FunctionComponent, ReactElement } from 'react';

export type NavigationMenuItemProps = {
  id: string;
  title: string;
  selected: boolean;
  icon: ReactElement;
  href?: string | RouteUrlInterface;
  key?: string;
  isReleased: boolean;
};

export const NavigationMenuItem: FunctionComponent<NavigationMenuItemProps> = (props) => {
  const { isOpen: drawerOpen, close } = useSidebar();
  const { id, title, icon, href, selected, isReleased } = props;
  const classes = useNavigationMenuItemStyles();
  const breakpoint = useWidth();
  const WrapWithTooltip = ({ children }) => {
    if (drawerOpen) return children;

    return (
      <CustomTooltip title={title} placement="right">
        {children}
      </CustomTooltip>
    );
  };

  return (
    <li>
      <WrapWithTooltip>
        <ListItemButton
          onClick={() =>
            /xs|sm/.test(breakpoint) ? close() : null
          }
          component={RoqLink}
          key={id}
          href={href}
          className={drawerOpen ? classes.listItemExpanded : classes.listItemCollapsed}
          sx={{
            justifyContent: drawerOpen ? 'initial' : 'center',
          }}
          selected={selected}
        >
          <ListItemIcon
            className={drawerOpen ? classes.listItemIconExpanded : classes.listItemIconCollapsed}
            sx={{
              minWidth: 0,
              mr: drawerOpen ? 3 : 'auto',
              justifyContent: 'center',
            }}
          >
            {resolveValue(icon)}
          </ListItemIcon>

          {drawerOpen && (
            <Stack flexDirection={'row'} alignItems={'center'} gap={'8px'}>
              <ListItemText
                primary={title}
                sx={{
                  margin: '0px !important',
                  alignSelf: 'self-end'
                }}
                classes={{ primary: selected ? classes.listItemPrimaryTextSelected : classes.listItemPrimaryText }}
              />
              {isReleased ? null :
                <Box sx={{
                  padding: '4px',
                  borderRadius: '999px',
                  background: 'rgba(255, 255, 255, 0.16)',
                  display: 'flex',
                  alignItems: 'center',
                  height: '22px',
                  width: '22px'
                }}>
                  <HourglassLow height={'14px'} width={'14px'} color='#FFFFFF80' />
                </Box>
              }
            </Stack>
          )}
        </ListItemButton>
      </WrapWithTooltip>
    </li>
  );
};
