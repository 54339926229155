import { Box, Button, CircularProgress, Divider } from '@mui/material';
import { RootState } from 'configuration/redux/reducer';
import { ActionOverlay } from 'modules/action-overlay/components/action-overlay';
import { CustomLabel } from 'modules/common/components/custom-label/custom-label.component';
import {
  useCurrentBusinessPartnerInternal,
  useCurrentBusinessPartnerSidebar,
} from 'modules/current-business-partner-selector/hooks';
import { CurrentBusinessPartnerInternalFlatInterface } from 'modules/current-business-partner-selector/hooks/use-current-business-partner-internal.hook';
import {
  AutocompleteCompanyOptionItemInterface,
  AutocompleteCompanySingle,
} from 'modules/forms/components/autocomplete-company/autocomplete-company-single.component';
import { UserRoleEnum } from 'modules/user-invites/enums';
import { useUserRoles } from 'modules/users/hooks';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';


export const CurrentBusinessPartnerSidebar: FunctionComponent = () => {
  const isOpen = useSelector((state: RootState) => state.currentBusinessPartnerSidebar.isOpen);
  const { t } = useTranslation();

  const [shouldRender, setRender] = useState<boolean>(true);

  const { closeBusinessPartnerSidebar } = useCurrentBusinessPartnerSidebar();

  const { currentBusinessPartnerInternal, changeCurrentBusinessPartnerInternal, isLoading } =
    useCurrentBusinessPartnerInternal();
  const [formValue, setFormValue] =
    useState<CurrentBusinessPartnerInternalFlatInterface>(currentBusinessPartnerInternal);

  const { roles } = useUserRoles();

  const handleChange = (value: AutocompleteCompanyOptionItemInterface) => {
    if (value) {
      setFormValue({
        id: value.id,
        name: value.label,
        kunnr: value.kunnr,
      });
    }
  };

  const handleCommitChange = useCallback(() => {
    if (formValue) {
      void changeCurrentBusinessPartnerInternal({
        id: formValue.id,
        name: formValue.name,
        kunnr: formValue.kunnr,
      });
    }
  }, [formValue?.id]);

  const handleReset = useCallback(() => {
    void changeCurrentBusinessPartnerInternal(null);
  }, []);

  useEffect(() => {
    setFormValue(currentBusinessPartnerInternal);
  }, [currentBusinessPartnerInternal]);

  useEffect(() => {
    if (isOpen !== shouldRender) {
      setRender(isOpen);
    }
  }, [isOpen]);

  const shouldShowReset = !roles?.includes(UserRoleEnum.CUSTOMER_USER) && currentBusinessPartnerInternal?.id;
  return (
    <ActionOverlay
      config={{
        title: t('company-view'),
        subTitle: t('select-company-see-data'),
        size: 'small'

      }}
      open={shouldRender}
      onClose={closeBusinessPartnerSidebar}
    >
      <Box sx={{ padding: 3 }}>
        <Box>
          <CustomLabel value='Select Company' />
          <AutocompleteCompanySingle
            placeholder={t('company')}
            value={formValue || null}
            onChange={handleChange}
            name={'currentBusinessPartner'}
          />
        </Box>
      </Box>
      <Divider />
      <Box sx={{ padding: 3, display: 'flex', flexDirection: 'column', gap: '12px' }}>
        <Button
          disabled={isLoading}
          variant="contained"
          size='large'
          color="primary"
          sx={{ height: 43, width: '100%'}}
          onClick={handleCommitChange}
        >
          {t('apply')}
          {isLoading && <CircularProgress color="inherit" size={16} />}
        </Button>
        <Button
          size='large'
          variant="outlined"
          sx={{ height: 43, width: '100%' }}
          onClick={closeBusinessPartnerSidebar}
        >
          {t('cancel')}
        </Button>
        {shouldShowReset && (
          <Button variant="text" size='small' sx={{ height: 43, width: '100%' }} color="secondary" onClick={handleReset}>
            {t('reset-selection')}
          </Button>
        )}
      </Box>
    </ActionOverlay>
  );
};
