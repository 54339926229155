import { Box, IconButton, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import { Bell, Buildings } from '@phosphor-icons/react';
import { MenuItemInterface } from 'layouts/main/interfaces';
import { useHasPermission } from 'modules/acl/hooks';
import { useAuth, useLogout } from 'modules/auth/hooks';
import { createUserInitials } from 'modules/auth/utils/user-initials';
import { CustomTooltip } from 'modules/common/components/custom-tooltip/custom-tooltip.component';
import { useTheme, useWidth } from 'modules/common/hooks';
import LogoutCircle from 'modules/common/icons/logout.svg';
import UserCircle from 'modules/common/icons/user-circle.svg';
import { truncateText } from 'modules/common/utils';
import {
  useCurrentBusinessPartnerInternal,
  useCurrentBusinessPartnerSidebar,
} from 'modules/current-business-partner-selector/hooks';
import { messageCenterSelector } from 'modules/message-center/selectors';
import { useNotifications, useNotificationsActions } from 'modules/notifications/hooks';
import { useIsHelmUser } from 'modules/users/hooks';
import { useTranslation } from 'next-i18next';
import { SyntheticEvent, useMemo } from 'react';
import { useSelector } from 'react-redux';
import routes from 'routes';

export interface UseHeaderMenuValuesInterface {
  classes: { badge: string; accountDropdownMenu: string; accountDropdownMenuMaskLayer: string };
  languagesOpen: boolean;
  hangleLanguageOpen: (event: SyntheticEvent) => void;
  handleOpenOverlay: () => void;
}

export interface UseHeaderMenuInterface {
  toolbarMenuItems: MenuItemInterface[];
  dropdownMenuItems: MenuItemInterface[];
}

const notXs = ({ width }) => !/xs/.test(width);

export const useHeaderMenu = (values: UseHeaderMenuValuesInterface): UseHeaderMenuInterface => {
  const { classes, languagesOpen, hangleLanguageOpen, handleOpenOverlay } = values;
  const isAdmin = useIsHelmUser();
  const { handleThemeChange } = useTheme();
  const { currentBusinessPartnerInternal } = useCurrentBusinessPartnerInternal();
  const width = useWidth();
  const { t } = useTranslation();
  const { handleLogout } = useLogout();
  const { user, isCustomerWithSingleBusinessPartnerInternal } = useAuth();
  const { setNotificationsSidebar } = useNotificationsActions();
  const { toggleBusinessPartnerSidebar } = useCurrentBusinessPartnerSidebar();
  const { sidebar, unreadCount: unreadNotificationsCount } = useNotifications();
  const { unreadCount: unreadMessagesCount } = useSelector(messageCenterSelector);
  const initials = useMemo(() => createUserInitials(user), [user]);
  const { isLoading, authorized } = useHasPermission('notifications.read');

  const items: MenuItemInterface[] = useMemo(
    () => [
      ...(currentBusinessPartnerInternal?.totalCount === 0
        ? []
        : [
          {
            id: 'current-business-partner',
            title: t('company-view'),
            noWrap: true,
            componentProps: {
              color: 'inherit' as const,
              onClick: () => !isCustomerWithSingleBusinessPartnerInternal ?  toggleBusinessPartnerSidebar() : '',
              sx: {
                mr: '12px',
                cursor: isCustomerWithSingleBusinessPartnerInternal ? 'default' : 'pointer',
              },
            },
            content: (
              <Box sx={{ display: 'flex', gap: '24px', alignItems: 'center' }}>
                {currentBusinessPartnerInternal?.id ? (
                  <>
                    {' '}
                    {!(/xs|sm/.test(width)) ?
                      <CustomTooltip
                        title={currentBusinessPartnerInternal.name}
                      >
                        <Typography fontSize={'14px !important'} marginTop={'4px'} lineHeight={'23.24px'} color={'primary'} fontWeight={600}
                        >
                          {truncateText(currentBusinessPartnerInternal.name, 20)}
                        </Typography>
                      </CustomTooltip>
                      : null
                    }
                  </>
                ) : (
                  <></>
                )}
                {!isCustomerWithSingleBusinessPartnerInternal ?

                  <CustomTooltip title={t('select-current-business-partner')}>
                    <IconButton sx={{ backgroundColor: '#EFF4F5', width: '32px', height: '32px', padding: '8px !important' }}>
                      <Buildings size={'16px'} />
                    </IconButton>
                  </CustomTooltip>
                  : null
                }
              </Box >
            ),
            meta: {
              toolbar: notXs,
              dropdown: () => false,
            },
          },
        ]),


      // NOTE: WE ARE FOR NOW NOT DISPLAYING NOTIFICATION MENU ITEM.
      ...(isLoading || !authorized
        ? []
        : [
          {
            id: 'notifications',
            title: t('notification'),
            componentProps: {
              color: 'inherit' as const,
              onClick: () => setNotificationsSidebar(!sidebar),
              sx: {
                ml: -0.5,
              },
              disabled: true
            },
            content: (
              <Badge
                className={classes.badge}
                badgeContent={unreadNotificationsCount}
                color="error"
                overlap="circular"
              >
                <Bell size={'16px'}/>
              </Badge>
            ),
            meta: {
              toolbar: notXs,
              dropdown: () => true,
            },
          },
        ]),

      {
        id: 'account',
        title: t('account'),
        componentProps: {
          color: 'inherit' as const,
          sx: { padding: 0, marginLeft: '12px' },
        },
        content: (
          <Box className={classes.accountDropdownMenuMaskLayer}>
            <Avatar
              src={user?.avatar}
              sx={{ width: 32, height: 32 }}
              className={classes.accountDropdownMenu}
              imgProps={{ crossOrigin: 'anonymous' }}
            >
              {initials}
            </Avatar>
          </Box>
        ),
        items: isAdmin
          ? [
            {
              id: 'settings',
              title: t('account-details', 'Account Details'),
              content: <UserCircle />,
              href: { route: routes.settings },
            },
            {
              id: 'logout',
              title: t('log-out'),
              content: <LogoutCircle />,
              componentProps: {
                onClick: handleLogout,
              },
            },
          ]
          : [
            {
              id: 'settings',
              title: t('account-details', 'Account Details'),
              content: <UserCircle />,
              href: { route: routes.settings },
            },
            {
              id: 'userInvite',
              title: 'Invite Your Team',
              content: <UserCircle />,
              componentProps: {
                onClick: handleOpenOverlay,
              },
            },
            {
              id: 'logout',
              title: t('log-out'),
              content: <LogoutCircle />,
              componentProps: {
                onClick: handleLogout,
              },
            },
          ],
        meta: {
          toolbar: notXs,
          dropdown: () => true,
        },
      },
    ],
    [
      unreadNotificationsCount,
      unreadMessagesCount,
      setNotificationsSidebar,
      handleThemeChange,
      languagesOpen,
      hangleLanguageOpen,
      handleLogout,
      isLoading,
      authorized,
    ],
  );

  const toolbarMenuItems = items.filter((x) => x.meta.toolbar({ width }));
  const dropdownMenuItems = items.filter((x) => x.meta.dropdown({ width }));

  return {
    toolbarMenuItems,
    dropdownMenuItems,
  };
};
