import { gql } from '@apollo/client';
import { AppDispatch } from 'configuration/redux/store';
import { requestGql } from 'modules/common/utils/request-gql';
import { UserInterface } from 'modules/users/interfaces';
import { activateUserAction } from 'modules/users/users.slice';
import { useDispatch } from 'react-redux';

export interface ActivateUserResponseInterface {
  user: UserInterface;
}

export interface UseActivateUserInterface {
  activateUser: (id: string, options?: { skipRedux: boolean }) => Promise<void | unknown>;
}

export const useActivateUser = (): UseActivateUserInterface => {
  const dispatch = useDispatch<AppDispatch>();

  const activateUser = async (id: string, options = { skipRedux: false }) => {
    const { skipRedux } = options;
    if (skipRedux) {
      return requestGql<ActivateUserResponseInterface>({
        mutation: gql`
          mutation ActivateUser($id: ID!) {
            activateUser(id: $id) {
              active
              id
            }
          }
        `,
        variables: { id },
      });
    }

    await dispatch(
      activateUserAction({
        mutation: gql`
          mutation ActivateUser($id: ID!) {
            activateUser(id: $id) {
              active
            }
          }
        `,
        variables: { id },
      }),
    );
  };

  return {
    activateUser,
  };
};
