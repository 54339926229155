import { gql } from '@apollo/client';
import { requestGql } from 'modules/common/utils/request-gql';
import { UserInviteInterface } from 'modules/user-invites';
import { useCallback } from 'react';
interface SuccessInterface {
  email: string;
}
interface ErrorInterface {
  email: string;
  error: string;
}
export interface UseSendInvitesResponse {
  success: SuccessInterface[];
  errors: ErrorInterface[];
}
export interface UseSendInvitesInterface {
    sendInvites: (userInviteArgs: UserInviteArgsInterface) => Promise<UseSendInvitesResponse>;
}

export interface UserInviteArgsInterface {
  businessEntityIds: string[];
  businessPartnerInternalIds: string[];
  userInvites: UserInviteInterface[];
  userRoleKey: string;
  createdByUserId: string;
}

export const useSendInvites = (): UseSendInvitesInterface => {
  const sendInvites = useCallback(
    async (userInviteArgs: UserInviteArgsInterface) =>
      requestGql<UseSendInvitesResponse>(
        {
          mutation: gql`
            mutation CreateUserInvites($userInvites: UserInvitesCreateDto!) {
              sendUserInvites(userInvites: $userInvites) {
                success {
                  email
                }
                errors {
                  error
                  email
                }
              }
            }
          `,
          variables: {
            userInvites: userInviteArgs,
          },
        },
        null,
        'sendUserInvites',
      ),
    [],
  );

  return {
    sendInvites,
  };
};
