import { Chip } from '@mui/material';
import { useTranslation } from 'modules/common/hooks';
import { UserInviteStatusEnum } from 'modules/user-invites/enums';
import { useUserStatusStyles } from 'modules/users/components/user-status/user-status.styles';
import { UserStatusEnum } from 'modules/users/enums';
import React, { FunctionComponent } from 'react';

export interface UserStatusInterface {
  status?: UserStatusEnum | UserInviteStatusEnum;
}

export const UserStatus: FunctionComponent<UserStatusInterface> = ({ status }) => {
  const classes = useUserStatusStyles();
  const { t } = useTranslation();
  /*
    We have this commented on purpose, for translations
    t('user.status.active')
    t('user.status.inactive')
    t('user.status.pending');
    t('user.status.accepted');
    t('user.status.expired');
    t('user.status.cancelled');
  */
  const translationKeys = {
    active: 'status.active',
    inactive: 'status.inactive',
    pending: 'user.status.pending',
    accepted: 'user.status.accepted',
    expired: 'user.status.expired',
    canceled: 'user.status.cancelled',
  };

  const normalizedStatus = status.toLocaleLowerCase();
  const label = translationKeys[normalizedStatus];
  const className = [classes.statusChip, classes[`${normalizedStatus}Chip`]].join(' ');
  return <Chip component="span" label={t(label)} className={className} />;
};
