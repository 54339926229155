import { PaperClasses, Theme } from '@mui/material';
import { roqMakeStyles } from 'modules/common/utils';

export interface CommonPaperClasses {
  paper?: PaperClasses;
  title?: string;
  divider?: string;
  square?: string
}

export const useCommonPaperStyles = roqMakeStyles<CommonPaperClasses>((theme: Theme) => ({
  'paper--root': {
    borderRadius: '2px',
  },
  square: {
    borderRadius: "0%"
  },
  title: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));
