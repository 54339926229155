import { UserRoleEnum } from 'modules/user-invites/enums';
import { useUserRoles } from 'modules/users/hooks/use-user-roles.hook';
import { useMemo } from 'react';

export const useIsAdminUser = (): boolean => {
  const { roles } = useUserRoles();
  const isHelmUser = useMemo(() => {
    const result = roles?.find((role) => [UserRoleEnum.HELM_ADMIN_USER].includes(role));
    return !!result;
  }, [roles]);

  return isHelmUser;
};
