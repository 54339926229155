import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import Menu from '@mui/material/Menu';
import { MenuItemInterface } from 'layouts/main/interfaces';
import { HeaderMenuDropdownItem } from 'layouts/main/partials/header-menu-dropdown-item';
import { HeaderMenuDropdownItemGroup } from 'layouts/main/partials/header-menu-dropdown-item-group';
import { useHeaderMenuItemStyles } from 'layouts/main/partials/header-menu-item/header-menu-item.styles';
import { CustomTooltip } from 'modules/common/components/custom-tooltip/custom-tooltip.component';
import { useMenuAnchor, useRouter } from 'modules/common/hooks';
import { RouteUrlInterface } from 'modules/common/interfaces';
import { resolveValue } from 'modules/common/utils/resolve-value';
import NextLink from 'next/link';
import { ComponentType, FunctionComponent, ReactNode, useMemo } from 'react';

export type HeaderMenuItemProps = {
  title: string;
  href?: string | RouteUrlInterface;
  component?: ComponentType<{ href?: string | RouteUrlInterface }>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  componentProps?: Record<string, any>;
  content: ReactNode;
  items?: MenuItemInterface[];
  noWrap?: boolean;
};

// TODO: refactor rendering menu items
export const HeaderMenuItem: FunctionComponent<HeaderMenuItemProps> = (props) => {
  const { title, href, component: Component, componentProps = {}, content, items = [], noWrap } = props;

  const classes = useHeaderMenuItemStyles();
  const { createPath } = useRouter();
  const [anchorEl, onMenuOpen, onMenuClose] = useMenuAnchor();
  const menuOpen = Boolean(anchorEl);

  const itemContent = useMemo(() => {
    if (noWrap) {
      return (
        <Box {...componentProps} onClick={componentProps.onClick}>
          {resolveValue(content)}
        </Box>
      );
    }
    if (Component) {
      return (
        <Component href={href} {...componentProps}>
          {resolveValue(content)}
        </Component>
      );
    }

    const iconButton = (
      <CustomTooltip
        title={title}
        PopperProps={{
          className: menuOpen ? classes.displayNone : '',
        }}
      >
        <IconButton
          {...componentProps}
          component={href ? 'a' : 'button'}
          onClick={items.length > 0 ? onMenuOpen : componentProps.onClick}
          className={classes.iconButton}
        >
          {resolveValue(content)}
        </IconButton>
      </CustomTooltip>
    );

    return href ? (
      <NextLink href={createPath(href)} passHref>
        {iconButton}
      </NextLink>
    ) : (
      iconButton
    );
  }, [Component, componentProps, content, onMenuOpen, href, menuOpen]);

  return (
    <>
      <ListItem className={classes.listItem}>{itemContent}</ListItem>
      {items && (
        <Menu
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={onMenuClose}
          onClick={onMenuClose}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          PaperProps={{
            sx: {
              marginTop: '8px',
              width: '13.75rem',
              boxShadow: '0px 3px 14px 2px rgba(116, 116, 133, 0.05), 0px 8px 10px 1px rgba(116, 116, 133, 0.10), 0px 5px 5px -3px rgba(116, 116, 133, 0.15) !important',
              '> ul': {},
            },
          }}
        >
          {items.map((x, i) =>
            x.items?.length ? (
              <HeaderMenuDropdownItemGroup key={x.id} after={!items[i]}>
                {x.items.map((y) => (
                  <HeaderMenuDropdownItem key={y.id} {...y} />
                ))}
              </HeaderMenuDropdownItemGroup>
            ) : (
              <HeaderMenuDropdownItem key={x.id} {...x} />
            ),
          )}
        </Menu>
      )}
    </>
  );
};
