import { gql } from '@apollo/client';
import { requestGql } from 'modules/common/utils/request-gql';
import { UserRoleEnum } from 'modules/user-invites/enums';
import { updateUserAction } from 'modules/users/users.slice';
import { useDispatch } from 'react-redux';

export interface UserUpdateValuesInterface {
  firstName?: string;
  lastName?: string;
  timezone?: string;
  locale?: string;
  phone?: string;
  position?: string;
  department?: string;
  businessPartnerInternalIds?: string[];
  businessEntityIds?: string[];
  userRoles?: UserRoleEnum[];
}

export interface UseUserUpdateInterface {
  updateUser: (id: string, user: UserUpdateValuesInterface) => Promise<UserUpdateValuesInterface>;
}

export const useUserUpdate = (): UseUserUpdateInterface => {
  const dispatch = useDispatch();

  const updateUser = (id: string, user: UserUpdateValuesInterface) =>
    requestGql(
      {
        mutation: gql`
          mutation updateUser($id: ID!, $user: UserUpdateDto!) {
            updateUser(id: $id, user: $user) {
              id
              email
              # disable the avatar selection until we resolved the issue in backend
              # avatar
              firstName
              lastName
              locale
              phone
              department
              position
              timezone
              userBusinessPartnerInternals {
                data {
                  businessPartnerInternal {
                    id
                    name
                    kunnr
                  }
                  businessEntity {
                    id
                    name
                  }
                }
              }
              userUserRoles {
                totalCount
                data {
                  userRole {
                    key
                    name
                  }
                }
              }
            }
          }
        `,
        variables: { id, user },
      },
      null,
      'updateUser',
    ).then(async (data) => {
      await dispatch(updateUserAction(data));
      return data;
    });

  return {
    updateUser,
  };
};
