import { FormikBag, FormikHelpers } from 'formik';
import { useAuth } from 'modules/auth/hooks';
import { isJsonString } from 'modules/common/utils';
import { useCallback, useState } from 'react';
import { useSendInvites, UseSendInvitesResponse } from 'views/user-invite/hooks/use-send-invites.hook';
import { UserInviteFormValueInterface } from 'views/user-invite/partials/invite-list/invite-list.partial';

export interface IBulkInviteResponse {
  errorCount: number;
  successCount: number;
}

export interface UseSendInviteFormInterface extends IBulkInviteResponse {
  handleSendInvite: (
    values: UserInviteFormValueInterface,
    actions: FormikHelpers<UserInviteFormValueInterface>,
  ) => void;
  resetResponseCount: () => void;
}

export const useSendInvitesForm = (): UseSendInviteFormInterface => {
  const { user } = useAuth();
  const { sendInvites } = useSendInvites();
  const [responseCount, setResponseCount] = useState<IBulkInviteResponse>({ errorCount: 0, successCount: 0 });
  const handleSendInvite = useCallback(
    async (
      values: UserInviteFormValueInterface,
      { setFieldValue, setErrors }: FormikBag<unknown, UserInviteFormValueInterface>,
    ) => {
      const { businessEntities, invitedCompanies, invites: invitesPayload, userRole } = values

      const invitesFormattedPayload = invitesPayload.map((item) => {
        const nameArray = item.name.split(' ');
        return ({
          email: item.email,
          firstName: nameArray[0],
          lastName: nameArray.slice(1).join(' ').length ? nameArray.slice(1).join(' ') : ' ',
        })
      })

      const formatPayload = {
        businessEntityIds: businessEntities.map((item) => item?.id),
        businessPartnerInternalIds: invitedCompanies,
        userRoleKey: userRole.toUpperCase(),
        userInvites: invitesFormattedPayload,
        createdByUserId: user.roqIdentifier
      }

      const { errors, success } = (await sendInvites(formatPayload)) as UseSendInvitesResponse;

      const newResponseCount = {
        successCount: success?.length || 0,
        errorCount: errors?.length || 0,
      };

      setResponseCount(newResponseCount);

      if (errors?.length === 0) {
        setFieldValue(
          'invites',
          [
            {
              name: '',
              department: '',
              email: '',
              position: '',
              companies: [],
              userRole: '',
            },
          ],
          false,
        );
        setFieldValue('invitedCompanies', [], false);
        return newResponseCount;
      }
      // In case of Partial success, when there are failed cases
      const { invites } = values;
      // get all records which were not invited
      const fieldsWithError = invites.filter(({ email }) => errors?.find((inviteError) => inviteError.email === email));
      setFieldValue('invites', fieldsWithError, false);
      // Get all errors
      const formErrors = invites?.reduce((acc, curr) => {
        const isFound = errors?.find(({ email }) => email === curr.email);
        if (isFound) {
          const { error } = isFound;
          const emailErrorMessage = isJsonString(error) ? JSON.parse(error)?.message : error;
          return [
            ...acc,
            {
              email: emailErrorMessage,
            },
          ];
        }
        return acc;
      }, []);
      setErrors({
        invites: formErrors,
      });

      if (success.length === 0) {
        throw new Error('client');
      } else {
        return newResponseCount;
      }
    },
    [],
  );

  const resetResponseCount = useCallback(() => {
    setResponseCount({
      successCount: 0,
      errorCount: 0,
    });
  }, []);

  return {
    handleSendInvite,
    resetResponseCount,
    ...responseCount,
  };

};
