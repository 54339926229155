import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { Grid, IconButton } from '@mui/material';
import { CustomTooltip } from 'modules/common/components/custom-tooltip/custom-tooltip.component';
import { useTranslation } from 'modules/common/hooks';
import { FormAlert } from 'modules/forms/components';
import { useActionButtonsStyles } from 'modules/user-company-management/components/action-buttons/action-buttons.styles';
import { CustomerUserInterface, HelmUserInterface } from 'modules/user-company-management/interfaces';
import { UserOperationStatusEnum } from 'modules/users/enums';
import { SingleUserChangeActiveStatusActionEnum } from 'modules/users/slices';
import React, { FunctionComponent, ReactNode, useMemo } from 'react';
import { useUserEditOperations } from 'views/users/hooks';
// eslint-disable-next-line @roq/imports-should-follow-conventions
import { UserChangeActivatedStatusConfirmation, UserDeleteConfirmation } from 'views/users/partials';

const confirmationsByOperationName = {
  activateUser: ({ onCancel, onConfirm, isLoading }) => (
    <UserChangeActivatedStatusConfirmation
      currentOperation={SingleUserChangeActiveStatusActionEnum.ACTIVATE}
      onCancel={onCancel}
      onConfirm={onConfirm}
      isLoading={isLoading}
    />
  ),
  deactivateUser: ({ onCancel, onConfirm, isLoading }) => (
    <UserChangeActivatedStatusConfirmation
      currentOperation={SingleUserChangeActiveStatusActionEnum.DEACTIVATE}
      onCancel={onCancel}
      onConfirm={onConfirm}
      isLoading={isLoading}
    />
  ),
  deleteUser: ({ onCancel, onConfirm, isLoading }) => (
    <UserDeleteConfirmation onCancel={onCancel} onConfirm={onConfirm} isLoading={isLoading} />
  ),
};

interface ActionButtonsInterface {
  rowData: CustomerUserInterface | HelmUserInterface;
  children?: ReactNode;
  onUserActivated?: (result: unknown) => void;
  onUserDeactivated?: (result: unknown) => void;
  onUserDeleted?: (result: unknown) => void;
  setSelectedUserId: (status: string) => void;
  setOverlay: (status: string) => void;
  allowDelete?: boolean;
}

export const ActionButtons: FunctionComponent<ActionButtonsInterface> = (props) => {
  const {
    rowData,
    onUserActivated,
    onUserDeactivated,
    onUserDeleted,
    setSelectedUserId,
    setOverlay,
    allowDelete = true,
  } = props;
  const classes = useActionButtonsStyles();
  const { t } = useTranslation();

  const handleOperationCompletion = (result, operationName) => {
    if (operationName === UserOperationStatusEnum.ACTIVATE_USER) {
      return onUserActivated(result);
    }

    if (operationName === UserOperationStatusEnum.DEACTIVATE_USER) {
      return onUserDeactivated(result);
    }

    if (operationName === UserOperationStatusEnum.DELETE_USER) {
      return onUserDeleted?.(result);
    }
  };

  const userActivatedStatusChangedAlert = ({ name, status, resetState }) => (
    <FormAlert
      open
      error={status.error}
      message={t('user-active-status-updated-successfully')}
      autoHideDuration={1500}
      onClose={() => {
        resetState();
        handleOperationCompletion(status?.result, name);
      }}
    />
  );

  const userDeletedAlert = ({ name, status, resetState }) => (
    <FormAlert
      open
      error={status.error}
      message={t('user-delete-success')}
      autoHideDuration={1500}
      onClose={() => {
        resetState();
        handleOperationCompletion(status?.result, name);
      }}
    />
  );

  const alertsByOperationName = {
    activateUser: userActivatedStatusChangedAlert,
    deactivateUser: userActivatedStatusChangedAlert,
    deleteUser: userDeletedAlert,
  };

  const { activateUser, deactivateUser, deleteUser, currentOperation, onConfirm, onCancel, resetState } =
    useUserEditOperations({
      userId: rowData.id,
    });

  const { active, canDeactivate } = useMemo(() => {
    const { firstName, lastName } = rowData;
    return {
      ...rowData,
      fullName: `${firstName} ${lastName}`,
      canDeactivate: rowData.active,
    };
  }, [rowData]);

  const handleActiveStatusChangePromptClick = () => (canDeactivate ? deactivateUser() : activateUser());
  const handleDeleteUser = () => {
    if (allowDelete) {
      deleteUser();
    }
  };
  const handleConfirm = (e) => {
    onConfirm(e);
  };

  return (
    <Grid container wrap="nowrap">
      <CustomTooltip title={t('title_edit-user')}>
        <IconButton
          onClick={() => {
            setSelectedUserId(rowData.id);
            setOverlay('open');
          }}
          className={classes.tableIcon}
        >
          <EditIcon />
        </IconButton>
      </CustomTooltip>

      {active && (
        <CustomTooltip title={t('user.change-status_deactivate')}>
          <IconButton className={classes.tableIcon} onClick={handleActiveStatusChangePromptClick}>
            <PauseCircleIcon />
          </IconButton>
        </CustomTooltip>
      )}

      {!active && (
        <CustomTooltip title={t('user.change-status_activate')}>
          <IconButton className={classes.tableIcon} onClick={handleActiveStatusChangePromptClick}>
            <PlayCircleIcon />
          </IconButton>
        </CustomTooltip>
      )}

      <CustomTooltip title={t('user.delete')}>
        <IconButton className={classes.tableIcon} onClick={handleDeleteUser} disabled={!allowDelete}>
          <DeleteIcon />
        </IconButton>
      </CustomTooltip>

      {!!currentOperation?.status
        ? alertsByOperationName[currentOperation.name]?.({ ...currentOperation, resetState, t })
        : !!currentOperation?.confirmable &&
          confirmationsByOperationName[currentOperation.name]?.({
            onConfirm: handleConfirm,
            onCancel,
            ...currentOperation,
          })}
    </Grid>
  );
};
