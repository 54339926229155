import { gql } from '@apollo/client';
import { AppDispatch } from 'configuration/redux/store';
import { requestGql } from 'modules/common/utils/request-gql';
import { UserInterface } from 'modules/users/interfaces';
import { deactivateUserAction } from 'modules/users/users.slice';
import { useDispatch } from 'react-redux';

export interface DeactivateUserResponseInterface {
  user: UserInterface;
}

export interface UseDeactivateUserInterface {
  deactivateUser: (id: string, options?: { skipRedux: boolean }) => Promise<void | unknown>;
}

export const useDeactivateUser = (): UseDeactivateUserInterface => {
  const dispatch = useDispatch<AppDispatch>();

  const deactivateUser = async (id: string, options = { skipRedux: false }) => {
    const { skipRedux } = options;
    if (skipRedux) {
      return requestGql<DeactivateUserResponseInterface>({
        mutation: gql`
          mutation DeactivateUser($id: ID!) {
            deactivateUser(id: $id) {
              active
              id
            }
          }
        `,
        variables: { id },
      });
    }

    await dispatch(
      deactivateUserAction({
        mutation: gql`
          mutation DeactivateUser($id: ID!) {
            deactivateUser(id: $id) {
              active
            }
          }
        `,
        variables: { id },
      }),
    );
  };

  return {
    deactivateUser,
  };
};
