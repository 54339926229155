import { Button, InputLabel, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { PencilSimple, Trash } from '@phosphor-icons/react';
import { FormikErrors } from 'formik';
import { isEmpty } from 'lodash';
import { USER_NAME_INPUT_PROPS } from 'modules/auth/constants';
import { CustomLabel } from 'modules/common/components/custom-label/custom-label.component';
import { UserInviteInterface } from 'modules/user-invites';
import { useTranslation } from 'next-i18next';
import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { useInviteFormRowStyles } from 'views/user-invite/partials/invite-form-row/invite-form-row.styles';

export interface InviteFormRowInterface {
  length?: number;
  index: number;
  invite: UserInviteInterface;
  companyIds: string[];
  parentGridStyling?: React.CSSProperties;
  onDelete: (index: number) => void;
  visibleErrors: FormikErrors<UserInviteInterface>;
  onBlur: (e: React.FocusEvent) => void;
  onChange: (field: string, value: unknown, shouldValidate?: boolean) => void;
  onReset: (e: unknown) => void;
  handleChange: {
    (e: ChangeEvent<unknown>): void;
    <T_1 = string | ChangeEvent<unknown>>(field: T_1): T_1 extends ChangeEvent<unknown>
      ? void
      : (e: string | ChangeEvent<unknown>) => void;
  };
  isCurrentUserCustomer?: boolean;
}

export interface Company {
  id: string;
  name?: string;
  kunnr: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export const InviteFormRow: FunctionComponent<InviteFormRowInterface> = (props: InviteFormRowInterface) => {
  const {
    invite,
    visibleErrors,
    index,
    handleChange,
    onChange,
    onBlur,
    onDelete,
    onReset,
    length,
    companyIds,
    isCurrentUserCustomer = false,
    parentGridStyling
  } = props;
  const classes = useInviteFormRowStyles(props);
  const { name = '', email = '' } = invite || {};
  const { t } = useTranslation();

  const [editState, setEditState] = useState({
    name: invite?.name || '',
    email: invite?.email || '',
    userRole: invite?.userRole || '',
    editMode: false,
  });

  const [readOnly, setReadOnly] = useState(!isEmpty(visibleErrors));
  useEffect(() => {
    if (!isEmpty(visibleErrors)) {
      setReadOnly(false);
    }
  }, [JSON.stringify(visibleErrors)]);


  useEffect(() => {
    if (length - 1 === index) {
      setReadOnly(false);
    } else {
      setReadOnly(true);
    }
  }, [length, index]);

  return (
    <Grid container gap={'14px'} p={'24px'} sx={parentGridStyling} >
      <Grid container sm={12} md={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
        <Grid item sm={6} md={6} lg={6}>
          <Typography fontWeight={600} fontSize={'16px'} lineHeight={'133.4%'} color={'black'}>User</Typography>
        </Grid>
        <Grid container sm={6} md={6} lg={6} className={classes.icons}>
          {readOnly && (
            <>
              <PencilSimple
                size={20}
                color={'#006173'}
                className={classes.pointerCursor}
                onClick={() => {
                  setEditState({
                    name: invite.name,
                    email: invite.email,
                    userRole: invite.userRole,
                    editMode: true,
                  });
                  setReadOnly(false);
                }}
              />
            </>
          )}
          {length > 1 && (
            <Trash className={classes.pointerCursor} onClick={() => onDelete(index)} size={20} color={'red'} />
          )}
        </Grid>
      </Grid>
      {readOnly ? (
        <>
          <Grid container gap={'12px'}>
            <Grid container item>
              <Grid item xs={3} md={3} lg={3} className={classes.colName}>
                Name:
              </Grid>
              <Grid item xs={9} md={9} lg={9}>
                {invite.name}
              </Grid>
            </Grid>
            <Grid container item>
              <Grid item xs={3} md={3} lg={3} className={classes.colName}>
                Email:
              </Grid>
              <Grid item xs={9} md={9} lg={9}>
                {invite.email}
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid container gap={'12px'}>
            <Grid item xs={12} md={12}>
              <CustomLabel value={'Name'} />
              <TextField
                sx={{ margin: '0 !important' }}
                name={`invites[${index}].name`}
                id={`invites[${index}].name]`}
                helperText={visibleErrors?.name}
                error={Boolean(visibleErrors?.name)}
                fullWidth
                inputProps={USER_NAME_INPUT_PROPS}
                onChange={handleChange}
                onBlur={onBlur}
                onReset={onReset}
                value={name || ''}
                size="small"
                variant="filled"
                InputLabelProps={{ shrink: !!name }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <CustomLabel value={t('input.email.placeholder')} />
              <TextField
                value={email || ''}
                sx={{ margin: '0 !important' }}
                size="small"
                variant="filled"
                fullWidth
                helperText={visibleErrors?.email}
                error={Boolean(visibleErrors?.email)}
                onChange={handleChange}
                onBlur={onBlur}
                onReset={onReset}
                id={`invites[${index}].email`}
                name={`invites[${index}].email`}
                InputLabelProps={{ shrink: !!email }}
              />
            </Grid>
          </Grid>
          {editState.editMode && (
            <Grid item container justifyContent="flex-end">
              <Grid item padding={1}>
                <Button
                  color="primary"
                  variant="outlined"
                  size="medium"
                  onClick={() => {
                    onChange(`invites[${index}].name`, editState.name);
                    onChange(`invites[${index}].email`, editState.email);
                    setReadOnly(true);
                    setEditState({
                      name: '',
                      email: '',
                      userRole: '',
                      editMode: false,
                    });
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item padding={1}>
                <Button
                  color="secondary"
                  variant="contained"
                  size="medium"
                  type="submit"
                  onClick={() => {
                    setReadOnly(true);
                    setEditState({
                      name: '',
                      email: '',
                      userRole: '',
                      editMode: false,
                    });
                  }}
                  disabled={!!visibleErrors?.name || !!visibleErrors?.email}
                >
                  Apply Changes
                </Button>
              </Grid>
            </Grid>
          )}
        </>
      )}

      {/* <Grid item sm={12} md={12}>
        <InputLabel>{t('input.department.placeholder')}</InputLabel>
        <TextField
          name={`invites[${index}].department`}
          id={`invites[${index}].department]`}
          helperText={visibleErrors?.department}
          error={Boolean(visibleErrors?.department)}
          fullWidth
          inputProps={USER_NAME_INPUT_PROPS}
          onChange={handleChange}
          onBlur={onBlur}
          onReset={onReset}
          value={department || ''}
          size="small"
          variant="filled"
          InputLabelProps={{ shrink: !!department }}
        />
      </Grid>

      <Grid item sm={12} md={12}>
        <InputLabel>{t('input.position.placeholder')}</InputLabel>
        <TextField
          name={`invites[${index}].position`}
          id={`invites[${index}].position]`}
          helperText={visibleErrors?.position}
          error={Boolean(visibleErrors?.position)}
          fullWidth
          inputProps={USER_NAME_INPUT_PROPS}
          onChange={handleChange}
          onBlur={onBlur}
          onReset={onReset}
          value={position || ''}
          size="small"
          variant="filled"
          InputLabelProps={{ shrink: !!position }}
        />
      </Grid> */}
    </Grid>
  );
};
