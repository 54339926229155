import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem, { menuItemClasses } from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import { alpha } from '@mui/system';
import { RoqLink } from 'modules/common/components/roq-link';
import { RouteUrlInterface } from 'modules/common/interfaces';
import { resolveValue } from 'modules/common/utils/resolve-value';
import { FunctionComponent, ReactNode } from 'react';

export type HeaderMenuDropdownItemProps = {
  title: string;
  href?: string | RouteUrlInterface;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  componentProps?: Record<string, any>;
  content: ReactNode;
};

export const HeaderMenuDropdownItem: FunctionComponent<HeaderMenuDropdownItemProps> = (props) => {
  const { title, href, componentProps = {}, content } = props;
  const theme = useTheme();

  return (
    <li>
      <MenuItem
        component={href ? RoqLink : 'div'}
        {...componentProps}
        href={href}
        sx={{
          paddingRight: '8px',
          paddingTop: '4px',
          paddingBottom: '4px',
          [`&.${menuItemClasses.root}`]: {
            color: theme.palette.text.primary,
          },
          [`&.${menuItemClasses.root}:hover`]: {
            color: theme.palette.text.primary,
            backgroundColor: alpha(theme.palette.primary.main, 0.16),
          },
        }}
      >
        <ListItemIcon
          sx={{
            color: theme.palette.primary.main,
            '& svg': {
              width: '1.5rem',
              height: '1.5rem',
              color: theme.palette.primary.main,
              fill: theme.palette.primary.main,
            },
          }}
        >
          {resolveValue(content)}
        </ListItemIcon>
        <ListItemText
          primary={title}
          sx={{
            '& .MuiTypography-root': {
              color: 'inherit',
              fontSize: '1rem',
              fontStyle: 'normal',
              fontWeight: 400,
            },
          }}
        />
      </MenuItem>
    </li>
  );
};
