import { Checkbox, FormHelperText, InputLabel } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import isArray from 'lodash/isArray';
import { useAuth } from 'modules/auth/hooks';
import { AutocompleteTag } from 'modules/common/components/autocomplete-tag/autocomplete-tag.component';
import { CustomLabel } from 'modules/common/components/custom-label/custom-label.component';
import { useAsyncOperation } from 'modules/common/hooks';
import { useFetchBusinessEntities } from 'modules/user-company-management/hooks/use-fetch-business-entities.hook';
import { BusinessEntityAutocompleteOptionInterface } from 'modules/user-company-management/interfaces/business-entity-autocomplete-option.interface';
import * as React from 'react';

interface IProps {
  label?: string;
  onChange: (selectedValue: BusinessEntityAutocompleteOptionInterface[]) => void;
  value: BusinessEntityAutocompleteOptionInterface | BusinessEntityAutocompleteOptionInterface[] | null;
  placeholder?: string;
  multiple?: boolean;
  disabled?: boolean;
  error?: boolean;
  helperText?: React.ReactNode;
}

export const BusinessEntitiesAutocompleteComponent: React.FunctionComponent<IProps> = (props: IProps) => {
  const { fetchEntities } = useFetchBusinessEntities();
  const { onChange, disabled = false, multiple, value: defaultValue, error, helperText } = props;
  const { label = 'Search Entities' } = props;
  const [value, setValue] = React.useState<
    BusinessEntityAutocompleteOptionInterface | BusinessEntityAutocompleteOptionInterface[] | null
  >(defaultValue);
  const [options, setOptions] = React.useState<readonly BusinessEntityAutocompleteOptionInterface[]>([]);
  const { user } = useAuth();
  const { status, initiateOperation } = useAsyncOperation({
    callback: fetchEntities,
  });
  const userBusinessPartners = React.useMemo(() => user.userBusinessPartnerInternals.data, [user]);

  React.useEffect(() => {
    if (!status?.result) return;
    let mappedOptions = []
    if (!userBusinessPartners.length) {
      mappedOptions = status.result.data.map((entity) => ({
        id: entity.id,
        name: entity.name,
        disabled: false
      }));
    } else {
      mappedOptions = status.result.data.map((entity) => {
        const entityExists = userBusinessPartners.some((partner) => partner.businessEntityId === entity.id);
        return {
          id: entity.id,
          name: entity.name,
          disabled: !entityExists,
        };
      });
    }

    setOptions(mappedOptions);
  }, [status, userBusinessPartners]);

  React.useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  React.useEffect(() => {
    void initiateOperation({
      offset: 0,
      limit: 30,
    });
  }, []);

  return (
    <>
      <CustomLabel value={label} />
      <Autocomplete
        disablePortal
        disabled={disabled}
        sx={{
          '& .MuiFormControl-root': {
            margin: '0px !important'
          }
        }}
        multiple={multiple}
        options={options}
        getOptionDisabled={(option: BusinessEntityAutocompleteOptionInterface) => option.disabled}
        renderTags={(tagValue: BusinessEntityAutocompleteOptionInterface[], getTagProps) =>
          tagValue.map((option, index) => (
            <AutocompleteTag
              disabled={option?.disabled}
              key={index}
              index={index}
              getTagProps={getTagProps}
              label={option.name}
            />
          ))
        }
        onChange={(_: React.SyntheticEvent, newValue) => {
          onChange(newValue as BusinessEntityAutocompleteOptionInterface[]);
        }}
        disableCloseOnSelect={multiple}
        value={value}
        getOptionLabel={(option: BusinessEntityAutocompleteOptionInterface) => `${option?.name}`}
        isOptionEqualToValue={(
          option: BusinessEntityAutocompleteOptionInterface,
          valueCheck: BusinessEntityAutocompleteOptionInterface,
        ) => option.id === valueCheck?.id || option.name === valueCheck.name}
        renderOption={(renderOptionProps, option: BusinessEntityAutocompleteOptionInterface, { selected }) => {
          const liProps = {
            ...renderOptionProps,
            ...{ key: option?.id || 1 },
          };

          return (
            <li {...liProps}>
              {multiple ?
                < Checkbox style={{ marginRight: 8 }} checked={selected} />
                : null
              }
              {option?.name}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            sx={{ marginTop: 1, marginBottom: 1 }}
            {...params}
            size="small"
            variant="filled"
            error={Boolean(error)}
          />
        )}
      />
      {error && helperText && (
        <FormHelperText sx={{ color: '#F75959' }}>
          {isArray(helperText) ? helperText.join('! ') : helperText}
        </FormHelperText>
      )}
    </>
  );
};
