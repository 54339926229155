import { OrderEnum } from 'modules/common/enums';
import { UserInviteSortEnum } from 'modules/user-invites/enums';
import { useFetchUserInvites } from 'modules/user-invites/hooks';
import { userInviteListSelector } from 'modules/user-invites/selectors';
import { UserInviteListStateInterface } from 'modules/user-invites/user-invites.slice';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export interface UseUserInvitesTableInterface extends UserInviteListStateInterface, UserInviteTableStateInterface {
  handlePageChange: (newPage: number, pageSize?: number) => void;
  handlePageSizeChange: (pageSize: number) => void;
  handleOrderChange: (sort: UserInviteSortEnum, order: OrderEnum) => void;
  refetchUserInvites: (pageNumber: number, pageSize: number) => void
}

export interface UserInviteTableStateInterface {
  pageNumber: number;
  pageSize: number;
  order: {
    sort: UserInviteSortEnum;
    order: OrderEnum;
  };
}

export const useUserInvitesTable = (): UseUserInvitesTableInterface => {
  const [tableState, setTableState] = useState<UserInviteTableStateInterface>({
    pageNumber: 0,
    pageSize: 10,
    order: { sort: UserInviteSortEnum.CREATED_AT, order: OrderEnum.DESC },
  });
  const { pageNumber, pageSize, order } = tableState;
  const { fetchUserInvites } = useFetchUserInvites();
  const userInviteList = useSelector(userInviteListSelector);

  useEffect(() => {
    fetchUserInvites({
      offset: pageNumber * pageSize,
      limit: pageSize,
      order,
    });
  }, [pageNumber, pageSize, order]);

  const handleOrderChange = useCallback(
    (sort: UserInviteSortEnum, orderDirection: OrderEnum) => {
      setTableState((ts) => ({
        ...ts,
        order: {
          sort,
          order: orderDirection,
        },
      }));
    },
    [setTableState],
  );

  const handlePageRowsCountChange = useCallback(
    (newPageSize: number) => {
      setTableState((ts) => ({
        ...ts,
        pageSize: newPageSize,
      }));
    },
    [setTableState],
  );

  const handlePageChange = useCallback(
    (newPageNumber: number, newPageSize: number) => {
      setTableState((ts) => ({
        ...ts,
        pageNumber: newPageNumber,
        pageSize: newPageSize,
      }));
    },
    [setTableState],
  );

  const refetchUserInvites = (page: number, size: number) => {
    fetchUserInvites({
      offset: page * size,
      limit: page,
      order,
    })
  }

  return {
    ...userInviteList,
    refetchUserInvites,
    pageNumber,
    pageSize,
    order,
    handlePageChange,
    handlePageSizeChange: handlePageRowsCountChange,
    handleOrderChange,
  };
};
