import { Divider } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Plus } from '@phosphor-icons/react';
import { CommonPaper } from 'modules/common/components/common-paper/common-paper.component';
import { UseEnhancedFormikInterface } from 'modules/forms/hooks';
import { BusinessEntityInterface } from 'modules/user-company-management/interfaces';
import { UserInviteInterface } from 'modules/user-invites';
import { UserRoleEnum } from 'modules/user-invites/enums';
import { useTranslation } from 'next-i18next';
import React, { FunctionComponent, ReactNode, useMemo } from 'react';
import { useAddNewFormRow } from 'views/user-invite/hooks';
import { InviteFormPartial } from 'views/user-invite/partials/invite-form/invite-form.partial';

export interface UserInviteFormValueInterface {
  businessEntities?: BusinessEntityInterface[];
  invitedCompanies?: string[];
  invites: UserInviteInterface[];
  userRole: UserRoleEnum
}

export interface InviteListPartialInterface {
  children?: ReactNode;
  formik: UseEnhancedFormikInterface<UserInviteFormValueInterface>;
  onClose?: () => void;
}

export const InviteListPartial: FunctionComponent<InviteListPartialInterface> = (props) => {
  const { formik, onClose } = props;
  const {
    isSubmitting,
    values,
    handleChange,
    setFieldValue,
    handleSubmit,
    handleBlur,
    isValid,
    errors,
    handleReset,
    resetForm,
  } = formik;

  const { t } = useTranslation();
  const { onAddRow, maxRowLimit } = useAddNewFormRow({
    value: values,
    onChange: setFieldValue,
    initialValue: {
      name: '',
      email: '',
      userRole: UserRoleEnum.CUSTOMER_USER,
    },
  });

  const disableAddButton = useMemo(() => values?.invites?.length === maxRowLimit, [values, maxRowLimit]);

  return (
    <CommonPaper>
      <Grid container component="form" onSubmit={handleSubmit} onReset={handleReset}>
        <Grid item xs={12}>
          <InviteFormPartial
            isSubmitting={isSubmitting}
            handleChange={handleChange}
            onChange={setFieldValue}
            onSubmit={handleSubmit}
            errors={errors}
            invites={values?.invites}
            onBlur={handleBlur}
            onReset={handleReset}
            disableSendInvite={!isValid || isSubmitting}
          />
        </Grid>

        <Grid item container justifyContent="flex-end" pb={'24px'}>
          <Grid item px={3} >
            <Button
              color="secondary"
              component="span"
              variant="text"
              size="medium"
              sx={{
                '& .MuiButton-startIcon': {
                  margin: '0 !important'
                },
                alignItems: 'end',
                gap: '6px',
                fontWeight: 600,
                lineHeight: '120%',
                letterSpacing: '0.4px',
              }}
              startIcon={<Plus size={19} />}
              disabled={disableAddButton || !!errors?.invites || !!errors?.invitedCompanies}
              onClick={onAddRow}
            >
              {t('add-another-user')}
            </Button>
          </Grid>
        </Grid>
        <Divider variant='fullWidth' sx={{ width: '400px' }} />
        <Grid item container p={'24px'} justifyContent="flex-end" gap={'14px'}>
          <Grid item>
            <Button
              color="primary"
              variant="outlined"
              size="large"
              onClick={() => {
                resetForm();
                onClose();
              }}
            >
              {t('cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              size="large"
              type="submit"
              disabled={!formik?.dirty || formik?.isSubmitting || !!Object.keys(formik?.errors).length}
            >
              {'Send Invitation'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </CommonPaper>
  );
};
