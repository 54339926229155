import { gql } from '@apollo/client';
import { requestGql } from 'modules/common/utils/request-gql';

export interface UseDeleteCustomEmailHookInterface {
  deleteCustomEmail: (id: string) => Promise<string>;
}

export const useHandleDeleteCustomEmail = (): UseDeleteCustomEmailHookInterface => {
  const deleteCustomEmail = (id: string): Promise<string> =>
    requestGql(
      {
        mutation: gql`
          mutation deleteCustomEmail($id: ID!) {
            deleteCustomEmail(id: $id)
          }
        `,
        variables: {
          id,
        },
      },
      null,
      'deleteCustomEmail',
    );

  return {
    deleteCustomEmail,
  };
};
