import { gql } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { OrderEnum } from 'modules/common/enums';
import { PaginationInterface } from 'modules/common/interfaces/pagination.interface';
import { requestGql } from 'modules/common/utils/request-gql';
import { BusinessEnittySortEnum } from 'modules/user-company-management/enums/business-entity-sort.enum';
import { BusinessEntityInterface } from 'modules/user-company-management/interfaces';

export interface BusinessEntityQueryInterface extends PaginationInterface {
  order?: {
    order: OrderEnum;
    sort: BusinessEnittySortEnum;
  };
  filter?: Record<string, unknown>;
}

export interface UseFetchBusinessEntitiesInterface {
  fetchEntities: (query?: BusinessEntityQueryInterface) => Promise<{
    data: BusinessEntityInterface[];
    totalCount: number;
  }>;
}

export const useFetchBusinessEntities = (): UseFetchBusinessEntitiesInterface => {
  const fetchEntities = (
    query?: BusinessEntityQueryInterface,
  ): Promise<{
    data: BusinessEntityInterface[];
    totalCount: number;
  }> =>
    requestGql(
      {
        query: gql`
          query BusinessEntities(
            $filter: BusinessEntityFilterArgType
            $limit: Float
            $offset: Float
            $order: BusinessEntityOrderArgType
          ) {
            businessEntities(
              filter: $filter
              limit: $limit
              offset: $offset
              order: $order
            ) {
              data {
                id
                name
              }
              totalCount
            }
          }
        `,
        variables: !isEmpty(query)
          ? {
            filter: query.filter,
            limit: query.limit,
            offset: query.offset,
            order: query.order,
          }
          : {},
      },
      null,
      'businessEntities',
    );

  return {
    fetchEntities,
  };
};
