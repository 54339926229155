import { gql } from '@apollo/client';
import { requestGql } from 'modules/common/utils/request-gql';

export interface BusinessPartnerInternalResponseInterface {
  id: string;
  name?: string;
  kunnr: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface UseFetchUsersHookInterface {
  fetchCompany: (id: string) => Promise<BusinessPartnerInternalResponseInterface>;
}

export const useFetchCompany = (): UseFetchUsersHookInterface => {
  const fetchCompany = async (id: string): Promise<BusinessPartnerInternalResponseInterface> =>
    requestGql(
      {
        query: gql`
          query QueryBusinessPartnerInternal($id: ID!) {
            businessPartnerInternal(id: $id) {
              id
              name
            }
          }
        `,
        variables: {
          id,
        },
      },
      null,
      'businessPartnerInternal',
    );

  return {
    fetchCompany,
  };
};
