import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ThemeEnum } from 'modules/theme/enums';

export const useNavigationMenuStyles = makeStyles((theme: Theme) => ({
  listCollapsed: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  divider: {
    backgroundColor: `rgba(255, 255, 255, 0.06)`,
    width: '100%',
  },
  dividerMaskLayer: {
    opacity: theme.palette.mode === ThemeEnum.LIGHT ? 0.3 : 0.5,
    backgroundColor: theme.palette.common.white,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  menuContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 60px)',
    justifyContent: 'space-between',
    paddingBottom: '12px',
  },
}));
