import { ModalClasses, Theme } from '@mui/material';
import { ActionOverlaySize } from 'modules/action-overlay/types';
import { roqMakeStyles } from 'modules/common/utils';

export interface ActionOverlayClasses {
  modal: ModalClasses;
  body: string;
  header: string;
  title: string;
  subTitle: string;
  content: string;
  formContent: string;
}

const getSizeWidth = (size: ActionOverlaySize): string => {
  switch (size) {
    case 'narrow':
    case 'xs':
      return `17.5rem`;
    case 'small':
      return `26rem`;
    case 'wide':
      return `47.5rem`;
    case 'extended':
      return '70vw';
    case 'large':
      return '90vw';
    case 'full':
      return `100vw`;
    case 'medium':
    default:
      return '36.8rem';
  }
};

export const useActionOverlayStyles = roqMakeStyles<ActionOverlayClasses>((theme: Theme) => ({
  'modal--paper': {
    boxShadow: 'none',
  },
  body: {
    padding: 0,
    margin: 0,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100vw',
    [theme.breakpoints.up(600)]: {
      width: (props: { size?: ActionOverlaySize }) => getSizeWidth(props.size),
    },
  },
  header: {
    display: 'flex',
    padding: '1.5rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  title: {
    fontStyle: 'normal',
    color: theme.palette.text.primary,
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '133.4%',
  },
  subTitle: {
    fontSize: '0.9375rem',
    fontStyle: 'normal',
    fontWeight: 400,
    color: theme.palette.text.secondary,
    marginTop: '0.5rem',
  },
  content: {
    flexGrow: 1,
  },
  formContent: {
    flexGrow: 1,
  },
}));
