import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useNavigationMenuItemStyles = makeStyles((theme: Theme) => ({
  listItemCollapsed: {
    color: theme.palette.common.white,
    '&.Mui-selected': {
      backgroundColor: theme.palette.secondary.main,
      '& svg': {
        fill: theme.palette.common.white,
      },
    },
    '&:hover': {
      backgroundColor: 'rgba(104, 192, 181, 0.3)',
      color: theme.palette.common.white,
      '& svg': {
        fill: theme.palette.common.white,
      },
    },
    '&.Mui-selected:hover': {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.common.white,
      '& svg': {
        fill: theme.palette.common.white,
        width: '20px',
        height: '20px'
      },
    },
    height: '48px',
  },
  listItemExpanded: {
    '&.Mui-selected': {
      backgroundColor: theme.palette.secondary.main,
      '& svg': {
        fill: theme.palette.common.white,
      },
    },
    '&.Mui-selected:hover': {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.common.white,
      '& svg': {
        fill: theme.palette.common.white,
      },
    },
    '&:hover': {
      backgroundColor: 'rgba(104, 192, 181, 0.3)',
      color: theme.palette.common.white,
      '& svg': {
        fill: theme.palette.common.white,
      },
    },
    alignItems: 'normal',
    height: '48px',
    padding: '14px 20px',
    '& svg': {
      width: '20px',
      height: '20px'
    }
  },
  listItemIconExpanded: {
    minWidth: 0,
    marginRight: '12px',
    justifyContent: 'center',
    '& svg': {
      fill: theme.palette.primary.contrastText,
    },
    '&:hover': {
      '& svg': {
        fill: theme.palette.common.white,
        backgroundColor: 'rgba(104, 192, 181, 0.3)',
      },
    },
  },
  listItemIconCollapsed: {
    minWidth: 0,
    margin: 'auto',
    justifyContent: 'center',
    '& svg': {
      fill: theme.palette.primary.contrastText,
      width: '20px',
      height: '20px'
    },
    '&:hover': {
      '& svg': {
        fill: theme.palette.common.white,
      },
    },
  },
  listItemPrimaryText: {
    ...theme.typography.body2,
    color: theme.palette.primary.contrastText,
    lineHeight: '123.5%',
    fontWeight: 400,
  },
  listItemPrimaryTextSelected: {
    ...theme.typography.body2,
    lineHeight: '123.5%',
    fontWeight: 700,
    color: '#ffffff',
    margin: '0px !important',

  },
}));
