import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Checkbox, FormControl, FormControlProps, FormHelperText, InputLabel, SxProps } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import debounce from 'lodash/debounce';
import findIndex from 'lodash/findIndex';
import isArray from 'lodash/isArray';
import { useUserCompany } from 'modules/auth/hooks';
import { AutocompleteTag } from 'modules/common/components/autocomplete-tag/autocomplete-tag.component';
import { CustomLabel } from 'modules/common/components/custom-label/custom-label.component';
import { useFetchCompanies } from 'modules/user-company-management/hooks';
import { useIsHelmUser } from 'modules/users/hooks';
import * as React from 'react';

export interface AutocompleteCompanyOptionItemInterface {
  id?: string;
  label?: string;
  name?: string;
  kunnr?: string;
}

interface IProps {
  label?: string;
  multiple?: boolean;
  value?: AutocompleteCompanyOptionItemInterface | string | string[] | AutocompleteCompanyOptionItemInterface[];
  defaultValue?: string;
  name: string;
  disabled?: boolean;
  variant?: 'outlined' | 'standard' | 'filled';
  showIdNameFilter?: boolean;
  placeholder?: string;
  helperText?: React.ReactNode;
  error?: boolean;
  formControlProps?: Omit<FormControlProps, 'variant'>;
  onChange: (selectedValue: AutocompleteCompanyOptionItemInterface | AutocompleteCompanyOptionItemInterface[]) => void;
  onBlur?: (e: React.SyntheticEvent) => void;
  includeHardcodedCompanyData?: boolean;
  formControlStyles?: SxProps;
}

export const AutocompleteCompany: React.FunctionComponent<IProps> = (props: IProps) => {
  const {
    label,
    variant,
    formControlProps,
    error,
    helperText,
    onChange,
    multiple,
    placeholder,
    includeHardcodedCompanyData,
    disabled,
    formControlStyles
  } = props;
  const { fetchCompanies } = useFetchCompanies();
  const { data: companyData } = useUserCompany();
  const [value, setValue] = React.useState<
    AutocompleteCompanyOptionItemInterface | string | string[] | AutocompleteCompanyOptionItemInterface[]
  >(props.value);
  const [inputValue, setInputValue] = React.useState('');
  const isHelm = useIsHelmUser();
  const [options, setOptions] = React.useState<readonly AutocompleteCompanyOptionItemInterface[]>([]);
  const [open, setOpen] = React.useState(false);
  const [isAutoSelect, setAutoSelect] = React.useState(false);
  const [isLoading, setLoading] = React.useState<boolean>(false);


  const fetchResults = React.useMemo(
    () =>
      debounce(async (request: { input: string }, callback: (results?) => void) => {
        const filterByName = !isHelm
          ? {
              name: {
                iLike: `%${request.input}%`,
              },
              id: {
                valueIn: companyData.map((x) => x.id),
              },
            }
          : {
              name: {
                iLike: `%${request.input}%`,
              },
            };

        const filterById = !isHelm
          ? {
              kunnr: {
                iLike: `%${request.input}%`,
              },
              id: {
                valueIn: companyData.map((x) => x.id),
              },
            }
          : {
              kunnr: {
                iLike: `%${request.input}%`,
              },
            };

        const companiesByName = await fetchCompanies({
          limit: 100,
          filter: filterByName,
        });

        const incluldeHelm = includeHardcodedCompanyData && isHelm;

        if (companiesByName.data.length > 0) {
          const filteredCompanies = incluldeHelm
            ? [...companiesByName.data.map((d) => ({ id: d.id, label: d.name, kunnr: d.kunnr }))]
            : companiesByName.data.map((d) => ({ id: d.id, label: d.name, kunnr: d.kunnr }));
          setLoading(true);
          callback(filteredCompanies);
        } else {
          const companiesById = await fetchCompanies({
            limit: 100,
            filter: filterById,
          });

          const filteredCompanies = incluldeHelm
            ? [...companiesById.data.map((d) => ({ id: d.id, label: d.name, kunnr: d.kunnr }))]
            : companiesById.data.map((d) => ({ id: d.id, label: d.name, kunnr: d.kunnr }));

          setLoading(true);
          callback(filteredCompanies);
        }
      }, 400),
    [includeHardcodedCompanyData],
  );

  // The React MUI Autocomplete component has two states that can be controlled:
  // - The "value" state with the value/onChange props combination. This state represents the value selected by the user, for instance when pressing Enter.
  // - The "input value" state with the inputValue/onInputChange props combination. This state represents the value displayed in the textbox.
  // In our case, we only need to reset the `value`
  React.useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  React.useEffect(() => {
    void fetchResults({ input: inputValue }, (results?: AutocompleteCompanyOptionItemInterface[]) => {
      const sortedOptions = results.sort((a, b) => a.label?.localeCompare(b.label));
      const helmIndex = findIndex(sortedOptions, { kunnr: '0' });

      if (helmIndex >= 1) {
        sortedOptions.splice(helmIndex, 1);
      }

      if (!isHelm && !isAutoSelect && sortedOptions?.length === 1) {
        setValue(sortedOptions);
        onChange(sortedOptions);
        setAutoSelect(true);
      }

      setOptions(sortedOptions);
      setLoading(false);
    });
  }, [value, inputValue, fetchResults, isHelm, isAutoSelect]);

  const icon = <CheckBoxOutlineBlankIcon />;
  const checkedIcon = <CheckBoxIcon />;
  return (
    <>
      <CustomLabel value={label} />
      <FormControl sx={{ ...(formControlStyles ? formControlStyles : {margin: 0}) }} fullWidth variant={variant} {...formControlProps} error={error}>
        <Autocomplete
          multiple={multiple}
          id="helm-customers"
          filterSelectedOptions
          sx={{
            '& .MuiFormControl-root': {
              margin: '0px !important'
            },
            margin: '0px !important'
          }}
          filterOptions={(o) => o}
          disabled={disabled || isAutoSelect}
          disableCloseOnSelect={multiple}
          value={value}
          isOptionEqualToValue={(
            option: AutocompleteCompanyOptionItemInterface,
            valueCheck: AutocompleteCompanyOptionItemInterface,
          ) => option.id === valueCheck?.id || option.name === valueCheck?.toString()}
          inputValue={inputValue}
          options={options}
          renderTags={(tagValue: AutocompleteCompanyOptionItemInterface[], getTagProps) =>
            tagValue.map((option, index) => (
              <AutocompleteTag
                key={index}
                disabled={disabled}
                index={index}
                getTagProps={getTagProps}
                label={`${option.label} - ${option.kunnr}`}
              />
            ))
          }
          getOptionLabel={(option: AutocompleteCompanyOptionItemInterface) => {
            if (option) {
              return typeof option === 'string' ? option : `${option?.label} - ${option?.kunnr}`;
            }
            return '';
          }}
          onChange={(_: React.SyntheticEvent, newValue: AutocompleteCompanyOptionItemInterface | null) => {
            onChange(newValue);
          }}
          onBlur={props.onBlur}
          onInputChange={(_, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                sx={{ margin: 0 }}
                size="small"
                variant="filled"
                placeholder={placeholder}
                error={Boolean(error)}
              />
            </>
          )}
          renderOption={(renderOptionProps, option: AutocompleteCompanyOptionItemInterface, { selected }) => {
            const liProps = {
              ...renderOptionProps,
              ...{ key: option?.id || 1 },
            };

            return (
              <li {...liProps}>
                {multiple && (
                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                )}
                {option?.label} - {option?.kunnr}
              </li>
            );
          }}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          loading={isLoading}
        />
      </FormControl>
      {error && helperText ? <FormHelperText sx={{ color: '#F75959'}} >{isArray(helperText) ? helperText.join('! ') : helperText}</FormHelperText> : null}
    </>
  );
};
